export interface Task {
  id: string
  title: string
  description: string
}

export interface UserInfo {
  name: string
  email: string
  role: string
  space_name: string
  logo_filename: string
}

export interface User {
  email: string
  password: string
  name: string
  role: string
}

export interface CsrfToken {
  csrf_token: string
}

export interface IProject {
  id: number;
  movie_folder: string;
  current_config: string;
  thumbnail: string;
  project_name: string;
  client_name: string;
  movie_title: string;
  progress: number;
  totalPhotos: number;
  output: string;
  lastEdited: string;
  generate_task_id: string;
  generate_start_time: string;
  generate_end_time: string;
}

export class Project implements IProject {
  id: number;
  movie_folder: string;
  current_config: string;
  thumbnail: string;
  project_name: string;
  client_name: string;
  movie_title: string;
  progress: number;
  totalPhotos: number;
  output: string;
  lastEdited: string;
  generate_task_id: string;
  generate_start_time: string;
  generate_end_time: string;

  constructor(data: IProject) {
    this.id = data.id;
    this.movie_folder = data.movie_folder;
    this.current_config = data.current_config;
    this.thumbnail = data.thumbnail;
    this.project_name = data.project_name;
    this.client_name = data.client_name;
    this.movie_title = data.movie_title;
    this.progress = data.progress;
    this.totalPhotos = data.totalPhotos;
    this.output = data.output;
    this.lastEdited = data.lastEdited;
    this.generate_task_id = data.generate_task_id;
    this.generate_start_time = data.generate_start_time;
    this.generate_end_time = data.generate_end_time;
    }

  static fromJSON(json: any): Project {
    return new Project({
      id: json.id,
      movie_folder: json.movie_folder,
      current_config: json.current_config,
      thumbnail: json.thumbnail,
      project_name: json.project_name,
      client_name: json.client_name,
      movie_title: json.movie_title,
      progress: json.progress,
      totalPhotos: json.totalPhotos,
      output: json.output,
      lastEdited: json.lastEdited,
      generate_task_id: json.generate_task_id,
      generate_start_time: json.generate_start_time,
      generate_end_time: json.generate_end_time,
    });
  }

  toJSON(): IProject {
    return {
      id: this.id,
      movie_folder: this.movie_folder,
      current_config: this.current_config,
      thumbnail: this.thumbnail,
      project_name: this.project_name,
      client_name: this.client_name,
      movie_title: this.movie_title,
      progress: this.progress,
      totalPhotos: this.totalPhotos,
      output: this.output,
      lastEdited: this.lastEdited,
      generate_task_id: this.generate_task_id,
      generate_start_time: this.generate_start_time,
      generate_end_time: this.generate_end_time,
    };
  }
}