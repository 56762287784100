// interfaces.ts
export interface ITitle {
    title: string;
    is_enable: boolean;
  }
  
  export interface IPart {
    title_list: ITitle;
    time_table: { [key: string]: number };
    photo_list: { [key: string]: string };
    effect_list: { [key: string]: string };
    message_list: { [key: string]: string[][] };
    point_list: number[];
  }
  
  export interface IMovieConfig {
    [key: string]: IPart;
  }
  
  // classes.ts
  //import { ITitle, IPart, IMovieConfig } from './interfaces';
  
  export class Title implements ITitle {
    constructor(
      public title: string,
      public is_enable: boolean
    ) {}
  }
  
  export class Part implements IPart {
    constructor(
      public title_list: Title,
      public time_table: { [key: string]: number },
      public photo_list: { [key: string]: string },
      public effect_list: { [key: string]: string },
      public message_list: { [key: string]: string[][] },
      public point_list: number[]
    ) {}
  
    static fromJSON(json: any): Part {
      return new Part(
        new Title(json.title_list.title, json.title_list.is_enable),
        json.time_table,
        json.photo_list,
        json.effect_list,
        json.message_list,
        json.point_list
      );
    }
  }
  
  export class MovieConfig implements IMovieConfig {
    [key: string]: Part | any;
  
    constructor(parts: { [key: string]: Part }) {
      Object.assign(this, parts);
    }
  
    static fromJSON(json: any): MovieConfig {
      const parts: { [key: string]: Part } = {};
      for (const [key, value] of Object.entries(json)) {
        parts[key] = Part.fromJSON(value);
      }
      return new MovieConfig(parts);
    }
  
    getPart(partNumber: number): Part | undefined {
      const key = `part${partNumber}`;
      return this[key];
    }
  
    getEnabledParts(): Part[] {
      return Object.values(this).filter(part => part.title_list.is_enable);
    }
  }