// 新しいページのコンポネント
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { FaPen } from 'react-icons/fa'; // FaPenのインポートを追加
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone'; // react-dropzoneのインポートを追加
import MovieGenerator from './MovieGenerator';

const NewMovie = () => {
  const [movieFiles, setMovieFiles] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('movie_a_config.json'); // 選択されたオプションを管理するための状態
  const [movieConfig, setMovieConfig] = useState<Record<string, unknown> | null>(null); // 映画の設定を管理するための状態
  const [progress, setProgress] = useState<{ [part: string]: { [key: string]: number | null } }>({}); // partとkeyごとにprogressの情報を保持するための状態を定義
  const [imageSrc, setImageSrc] = useState<{ [key: string]: string | null }>({}); // 画像ソースを管理するための状態を追加
  const [isDragActive, setIsDragActive] = useState<{ [key: string]: boolean }>({}); // ドラッグアクティブ状態を管理するための状態を追加
  const [movieFolderPath, setMovieFolderPath] = useState<string | null>(null); // 動画フォルダパスを管理するための状態を追加

  const location = useLocation();
  const new_folder_name = location.pathname.split('/').pop();

  useEffect(() => {
    const getMovie = async () => {
      try {
        console.log(new_folder_name);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/movie_folders/gachimoto/${new_folder_name}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setMovieFiles(response.data.movie_files);
      } catch (error) {
        console.error('Movie files not found');
      }
    };

    getMovie();
  }, [new_folder_name]);

  useEffect(() => {
    const getMovieConfig = async () => {
      try {
        console.log(selectedOption);
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/movie_config/gachimoto/${new_folder_name}/${selectedOption}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        setMovieConfig(response.data.config);
      } catch (error) {
        console.error('Movie config not found');
      }
    };

    getMovieConfig();
  }, [selectedOption, new_folder_name]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const fetchFileContent = async (fileName: string, part: string, key: string) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/movie_file/gachimoto/${new_folder_name}/input/${fileName}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(prevProgress => ({ ...prevProgress, [part]: { ...prevProgress[part], [key]: progress } })); // ダウンロードの進捗を更新
        },
      });
      const base64Content = response.data.base64_content; // base64形式のコンテンツを取得
      const contentType = fileName.split('.').pop(); // ファイル名から拡張子を取得
      const src = `data:${contentType};base64,${base64Content}`; // Base64形式のコンテンツを返す
      setImageSrc(prevImageSrc => ({ ...prevImageSrc, [`photo-${part}-${key}`]: src })); // 画像ソースを更新
      return src;
    } catch (error) {
      console.error('File not found');
      return null;
    } finally {
      setProgress(prevProgress => ({ ...prevProgress, [part]: { ...prevProgress[part], [key]: null } })); // ダウンロードが完了したら進捗バーを非表示にする
    }
  };

  // fetchFileContentが実行されていない問題を解決するために、fetchFileContentを呼び出すコードを追加
  useEffect(() => {
    if (movieConfig) {
      Object.keys(movieConfig).forEach(part => {
        Object.keys((movieConfig as any)[part].photo_list || {}).forEach(key => {
          fetchFileContent((movieConfig as any)[part].photo_list[key], part, key);
        });
      });
    }
  }, [movieConfig, new_folder_name, setProgress, setImageSrc]);

  const handleUploadPhoto = async (part: string, key: string) => {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', 'image/*');
    fileInput.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const result = e.target?.result; // 'e.target' is possibly 'null'の問題を解決するために、オプショナルチェーンを使用
          if (result) {
            console.log(`File dropped: ${file.name}`); // ドラッグ＆ドロップが完了したらファイル名を表示
            
            // ファイルをサーバーにアップロード
            try {
              const base64Result = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64 = reader.result?.toString().split(',')[1];
                  if (base64) {
                    resolve(base64);
                  } else {
                    reject(new Error('Base64 encoding failed'));
                  }
                };
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file);
              });

              const response = await fetch(`${process.env.REACT_APP_API_URL}/movie_file/gachimoto/${new_folder_name}/input/${file.name}?part=${part}&key=${key}&config_file_name=movie_a_config.json`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ base64: base64Result }),
              });

              if (!response.ok) {
                throw new Error('Failed to upload file');
              }

              const uploadResult = await response.json();
              console.log(uploadResult.message); // アップロード成功メッセージをコンソールに出力
              fetchFileContent(file.name, part, key); // ファイルのアップロードが成功したら、fetchFileContentを実行
            } catch (error) {
              console.error('Failed to upload file', error); // アップロード失敗時のエラーをコンソールに出力
            }
          }
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const handleDrop = async (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    const divElement = event.currentTarget as HTMLElement;
    if (!divElement) {
      console.error('Div element not found');
      return;
    }
    const divId = divElement.id; // divのidを取得
    // divIdがphoto-front-1ならpart=front, key=1
    const part = divId.split('-')[1];
    const key = divId.split('-')[2];

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        const result = e.target?.result; // 'e.target' is possibly 'null'の問題を解決するために、オプショナルチェーンを使用
        if (result) {
          console.log(`File dropped: ${file.name}`); // ドラッグ＆ドロップが完了したらファイル名を表示
          console.log(`Dropped div id: ${divId}`); // 取得したdivのidをコンソールに出力

          // ファイルをサーバーにアップロード
          try {
            const base64Result = await new Promise<string>((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                const base64 = reader.result?.toString().split(',')[1];
                if (base64) {
                  resolve(base64);
                } else {
                  reject(new Error('Base64 encoding failed'));
                }
              };
              reader.onerror = error => reject(error);
              reader.readAsDataURL(file);
            });

            const response = await fetch(`${process.env.REACT_APP_API_URL}/movie_file/gachimoto/${new_folder_name}/input/${file.name}?part=${part}&key=${key}&config_file_name=movie_a_config.json`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ base64: base64Result }),
            });

            if (!response.ok) {
              throw new Error('Failed to upload file');
            }

            const uploadResult = await response.json();
            console.log(uploadResult.message); // アップロード成功メッセージをコンソールに出力
            fetchFileContent(file.name, part, key); // ファイルのアップロードが成功したら、fetchFileContentを実行
          } catch (error) {
            console.error('Failed to upload file', error); // アップロード失敗時のエラーをコンソールに出力
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ 
    onDrop: (acceptedFiles, fileRejections, event) => handleDrop(acceptedFiles, fileRejections, event),
    noClick: true, // クリックでのファイル選択を無効化
  });

  const handleGenerateMovie = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/generate_movie/gachimoto/${new_folder_name}/${selectedOption}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setMovieFolderPath(response.data.movie_folder_path);
    } catch (error) {
      console.error('Failed to generate movie', error);
    }
  };

  return (
    <div className="p-4">
      <header className="flex justify-between items-center mb-4">
        <div>
          <h1 className="text-xl font-semibold">佐藤様 新築 江津湖畔</h1>
        </div>
        <button className="bg-blue-500 text-white px-4 py-2 rounded">
          Topへ戻る
        </button>
      </header>
      <div className="mb-6">
        <label className="block mb-2 font-semibold">依頼主</label>
        <input
          name="clientName"
          className="border border-gray-300 p-2 w-full mb-4"
          type="text"
          defaultValue="佐藤"
        />
        <label className="block mb-2 font-semibold">動画タイトル</label>
        <input
          name="videoTitle"
          className="border border-gray-300 p-2 w-full"
          type="text"
          defaultValue="sato family haus"
        />
      </div>
      <div className="mb-4">
        <div>
          <input type="radio" id="movie_a_config.json" name="options" value="movie_a_config.json" checked={selectedOption === 'movie_a_config.json'} onChange={handleOptionChange} />
          <label htmlFor="movie_a_config.json" className="ml-2">楽曲A：新築向け　　　　5分30秒　総写真数 52枚</label>
        </div>
        <div>
          <input type="radio" id="movie_b_config.json" name="options" value="movie_b_config.json" checked={selectedOption === 'movie_b_config.json'} onChange={handleOptionChange} />
          <label htmlFor="movie_b_config.json" className="ml-2">楽曲B：新築向け　　　　6分00秒　総写真数 60枚</label>
        </div>
        <div>
          <input type="radio" id="movie_c_config.json" name="options" value="movie_c_config.json" checked={selectedOption === 'movie_c_config.json'} onChange={handleOptionChange} />
          <label htmlFor="movie_c_config.json" className="ml-2">楽曲C：リノベーション向け　5分30秒　総写真数 52枚</label>
        </div>
        <div>
          <input type="radio" id="movie_d_config.json" name="options" value="movie_d_config.json" checked={selectedOption === 'movie_d_config.json'} onChange={handleOptionChange} />
          <label htmlFor="movie_d_config.json" className="ml-2">楽曲D：SNS向け　　　　0分15秒　総写真数 10枚</label>
        </div>
      </div>
      {movieFiles.length > 0 ? (
        <div className="mb-4">
          {/* <h1 className="text-xl font-bold">Movie Files</h1>
          <ul>
            {movieFiles.map((file, index) => (
              <li key={index} className="mb-1">{file}</li>
            ))}
          </ul> */}
        </div>
      ) : (
        <h1>Movie files not found</h1>
      )}
      {movieConfig && (
        <div>
          {/* <h1>Movie Config</h1>
          <pre>{JSON.stringify(movieConfig, null, 2)}</pre> */}
        </div>
      )}
      {movieConfig && (
        <div className="text-lg font-bold w-full">
          {/* <h1 className="text-2xl font-bold mb-4">各パートのタイトル</h1> */}
          {Object.keys(movieConfig).map((part: string) => (
            <div key={part} className="mb-4">
              <div className="text-lg font-semibold flex justify-between items-center mb-2">
                <span>{String((movieConfig as any)[part].title_list?.title || 'タイトルなし')}</span>
                {(movieConfig as any)[part].title_list?.is_enable && (
                  <button className="text-black font-bold flex items-center justify-center">
                    <FaPen />
                  </button>
                )}
              </div>
              <hr className="my-2 border-t border-gray-300 w-full" />
              <div>
                {Object.keys((movieConfig as any)[part].photo_list || {}).length > 0 && (
                  <div className="flex flex-wrap">
                    {Object.keys((movieConfig as any)[part].photo_list || {}).map((key) => (
                      !(movieConfig as any)[part].message_list[key] && (
                        <div key={key} id={`photo-${part}-${key}`} className={`w-[300px] h-[241px] bg-white shadow-md rounded-lg overflow-hidden font-roboto m-2 relative ${isDragActive[`photo-${part}-${key}`] ? 'border-2 border-dashed border-blue-500' : ''}`} {...getRootProps({ 
                        onDragOver: (e) => {
                          e.preventDefault();
                          setIsDragActive(prevIsDragActive => ({ ...prevIsDragActive, [`photo-${part}-${key}`]: true })); 
                        }, 
                        onDragLeave: () => {setIsDragActive(prevIsDragActive => ({ ...prevIsDragActive, [`photo-${part}-${key}`]: false }))},
                        onDrop: (e) => {
                          e.preventDefault();
                          setIsDragActive(prevIsDragActive => ({ ...prevIsDragActive, [`photo-${part}-${key}`]: false }));
                          handleDrop(Array.from(e.dataTransfer.files), [], e) }})}>
                          <input {...getInputProps()} onClick={(e) => e.stopPropagation()} />
                          <div className="h-[70%] bg-gray-200 flex items-center justify-center cursor-pointer" >
                            {imageSrc[`photo-${part}-${key}`] ? (
                              <img src={imageSrc[`photo-${part}-${key}`] || ''} alt={key} className="h-full w-full object-cover" />
                            ) : (
                              <div className="loader"></div>
                            )}
                            {!imageSrc[`photo-${part}-${key}`] && (
                              <div className="flex flex-col items-center justify-center">
                                <button 
                                  className="mb-2 text-center text-gray-500 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded cursor-pointer"
                                  onClick={() => handleUploadPhoto(part, key)}
                                >
                                  写真を選択してください
                                </button>
                                <span className="text-center text-gray-500">
                                  もしくは<br />
                                  ドラッグ＆ドロップ
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="h-[20%] bg-white p-4 flex flex-col">
                            <span>{(movieConfig as any)[part].photo_list[key]}</span>
                            <span className="text-sm text-gray-500">{part}, {key}</span>
                          </div>
                          <div className="h-[10%] bg-white p-4">
                            {progress[part]?.[key] && (
                              <div className="progress-bar w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress[part][key]}%` }}></div>
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    ))}
                  </div>
                )}
              </div>
              <div>
                {Object.keys((movieConfig as any)[part].message_list || {}).length > 0 && (
                  <div className="flex flex-col">
                    {Object.keys((movieConfig as any)[part].message_list || {}).map((key) => (
                      <div key={key} className="w-[622px] h-[350px] shadow-md rounded-lg overflow-hidden font-roboto m-2" style={{ color: ['black', 'ending', 'end', 'endroll'].includes((movieConfig as any)[part].effect_list[key]) ? 'white' : 'black' }}>
                        <div className="h-full flex flex-col items-center justify-center" style={{ backgroundImage: `url(${imageSrc[`photo-${part}-${key}`] || ''})`, backgroundSize: 'cover' }}>
                          <span className="text-gray-500 text-center">
                            {((movieConfig as any)[part].message_list[key] || []).map((message: string, index: number) => (
                              <p key={index} style={{ color: ['black', 'ending', 'end', 'endroll'].includes((movieConfig as any)[part].effect_list[key]) ? 'white' : 'black' }}>{message}</p>
                            ))}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <div>
        <button onClick={handleGenerateMovie} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          動画生成
        </button>
        <a
          href={movieFolderPath || ''}
          className="flex-1 text-gray-800 font-roboto"
        >
          {movieFolderPath}
        </a>
        <button className="ml-2 text-gray-600">
          <i className="fas fa-clipboard"></i>
        </button>
      </div>
    </div>
  );
};

export default NewMovie;