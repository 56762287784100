import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { MovieConfig, Part } from '../types/movie_config';
import { UserInfo } from '../types/types';
import { Project } from '../types/types';

const CheckMovie: React.FC = () => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
    const [project, setProject] = useState<Project>();
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const [movieFiles, setMovieFiles] = useState<string[]>([]);
    const [movieConfig, setMovieConfig] = useState<MovieConfig | null>(null);
    
    const location = useLocation();
    const new_folder_name = location.pathname.split('/').pop();

    const fetchAllData = useCallback(async () =>
    {
        try {
        // ユーザー情報を取得
            const userResponse = await axios.get<UserInfo>(`${process.env.REACT_APP_API_URL}/user`, {
                withCredentials: true
            });
            const currentUserInfo = userResponse.data;
            setUserInfo(currentUserInfo);

            // ロゴを取得（ユーザー情報にロゴファイル名がある場合のみ）
            if (userResponse.data.logo_filename)
            {
                const logoResponse = await axios.get(`${process.env.REACT_APP_API_URL}/logo`, {
                    responseType: 'blob',
                    withCredentials: true
                });
                const url = URL.createObjectURL(logoResponse.data);
                setLogoUrl(url);
            }

            // プロジェクト設定を取得
            const response1 = await axios.get(`${process.env.REACT_APP_API_URL}/project/${currentUserInfo?.space_name}/${new_folder_name}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const currentProject = response1.data.project;
            setProject(currentProject);
            
            // ファイル一覧を取得
            console.log(new_folder_name);
            const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/movie_folders/${currentUserInfo?.space_name}/${new_folder_name}`, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
            setMovieFiles(response2.data.movie_files);

            // Movie configを取得
            const response3 = await axios.get(`${process.env.REACT_APP_API_URL}/movie_config/${currentUserInfo?.space_name}/${new_folder_name}/${currentProject.current_config}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const config = MovieConfig.fromJSON(response3.data.config);
            
            setMovieConfig(config);
        } catch (error)
        {
        console.error('Error fetching data:', error);
        }
    }, []);

  useEffect(() => {
    fetchAllData();

    return () => {
      if (logoUrl) {
        URL.revokeObjectURL(logoUrl);
      }
    };
  }, [fetchAllData]);

  if (!movieConfig) {
    return <div>Loading...</div>;
  }

  const renderPart = (part: Part, index: number) => (
    <div key={index}>
      <h2>{part.title_list.title}</h2>
      <p>Enabled: {part.title_list.is_enable ? 'Yes' : 'No'}</p>
      <h3>Photos:</h3>
      <ul>
        {Object.entries(part.photo_list).map(([key, value]) => (
          <li key={key}>Photo {key}: {value}</li>
        ))}
      </ul>
      {/* 他のデータも同様に表示 */}
    </div>
  );

  return (
    <div>
      <h1>Movie Configuration</h1>
      {Object.values(movieConfig).map((part, index) => renderPart(part, index))}
    </div>
  );
};

export default CheckMovie;