const getWebSocketUrl_progress = (taskId: string) => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  let host = window.location.host;
  let path = '';

  if (process.env.REACT_APP_API_URL) {
    const url = new URL(process.env.REACT_APP_API_URL);
    host = url.host;
    path = url.pathname.replace(/\/api$/, ''); // /api を除去
  }

  return `${protocol}//${host}${path}/ws/movie_progress/${taskId}`;
};

const getWebSocketUrl_elapsedtime = (taskId: string) => {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
  let host = window.location.host;
  let path = '';

  if (process.env.REACT_APP_API_URL) {
    const url = new URL(process.env.REACT_APP_API_URL);
    host = url.host;
    path = url.pathname.replace(/\/api$/, ''); // /api を除去
  }

  return `${protocol}//${host}${path}/ws/movie_elapsedtime/${taskId}`;
};

export function connectWebSocket_progress(taskId: string, onProgress: (progress: number) => void) {
  const ws = new WebSocket(getWebSocketUrl_progress(taskId));
  
  ws.onopen = () => {
    console.log('WebSocket接続が確立されました');
    console.log('接続URL:', getWebSocketUrl_progress(taskId));
  };

  ws.onmessage = (event) => {
    console.log('WebSocketメッセージを受信:', event.data);
    try {
      const data = JSON.parse(event.data);
      onProgress(data.progress);
    } catch (error) {
      console.error('WebSocketメッセージの解析エラー:', error);
    }
  };
  
  ws.onerror = (error) => {
    console.error('WebSocketエラー:', error);
  };

  ws.onclose = (event) => {
    console.log('WebSocket接続が閉じられました');
    console.log('閉じた理由:', event.reason);
    console.log('コード:', event.code);
  };

  return () => {
    ws.close();
  };
}

export function connectWebSocket_elapsedtime(taskId: string, onElapsedTimeUpdate: (elapsedTime: number, status: string) => void, onError: (error: string) => void) {
  const ws = new WebSocket(getWebSocketUrl_elapsedtime(taskId));
  
  ws.onopen = () => {
    console.log('WebSocket接続が確立されました');
  };

  ws.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      if (data.status === "error") {
        //onError(data.message);
        onError("動画生成がビジーです。しばらく経ってから再度お試しください。");
        ws.close();
      }
      else if(data.status === "no_id")
      {
        onElapsedTimeUpdate(0, data.status);
        ws.close();
      }
      else if(data.status === "waiting")
      {
        onElapsedTimeUpdate(0, data.status);
      }
      else if(data.status === "ready")
      {
        onElapsedTimeUpdate(0, data.status);
      }
      else if(data.elapsed_time !== undefined && data.status !== undefined)
      {
        onElapsedTimeUpdate(data.elapsed_time, data.status);
        if (data.status === "finished") {
          ws.close();
        }
      }
    } catch (error) {
      console.error('WebSocketメッセージの解析エラー:', error);
      onError('WebSocketメッセージの解析エラーが発生しました');
    }
  };
  
  ws.onerror = (error) => {
    console.error('WebSocketエラー:', error);
    onError('WebSocket接続エラーが発生しました');
  };

  ws.onclose = (event) => {
    console.log('WebSocket接続が閉じられました');
  };

  return () => {
    if (ws.readyState === WebSocket.OPEN) {
      ws.close();
    }
  };
}