import React, { useEffect, useState } from 'react';


export const Movie: React.FC = () => {
  const [movies, setMovies] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [newMovie, setNewMovie] = useState<string>('');


  useEffect(() => {
    const getMovies = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/movies/gachimoto`); // space_nameは適切な値に置き換えてください
        if (!response.ok) {
          throw new Error('Movies not found');
        }
        const data = await response.json();
        setMovies(data.movies);
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);
        } else {
          setError('An unknown error occurred');
        }
      }
    };

    getMovies();
  }, []);

  const handleCreateMovie = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/movies/gachimoto`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ space_name: 'gachimoto' }),
      });
      if (!response.ok) {
        throw new Error('Failed to create movie');
      }
      const data = await response.json();
      setNewMovie(data.new_movie_folder);
      window.location.href = `http://localhost:3000/movie/${data.new_movie_folder}`;
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An unknown error occurred');
      }
    }
  };

  const handleMovieClick = (movieFolder: string) => {
    window.location.href = `http://localhost:3000/movie/${movieFolder}`;
  };

  return (
    <div>
      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleCreateMovie}>新しく案件を作成する</button>
      {error ? <div>Error: {error}</div> : (
        <>
          <div>新しい案件: {newMovie}</div>
          <div className="flex flex-col">
            {movies.map((movie, index) => (
              <div key={index} className="p-4 max-w-sm mb-4" onClick={() => handleMovieClick(movie)}>
                <div className="flex items-center bg-white rounded-lg shadow-md p-4 w-full">
                  <div className="relative">
                    <img
                      src="/path/to/image.jpg"
                      alt={movie} // folder名をaltに設定
                      className="w-[100px] h-[100px] rounded-lg"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};


