import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useAppDispatch } from '../app/hooks'
import { resetEditedTask, toggleCsrfState } from '../slices/appSlice'
import { User } from '../types/types'
import { UserInfo } from '../types/types';

export const useMutateAuth = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()

  const loginMutation = useMutation(
    async (user: User) =>
      await axios.post<UserInfo>(`${process.env.REACT_APP_API_URL}/login`, user, {
        withCredentials: true,
      }),
    {
//      onSuccess: () => {
//        // history.push('/todo')
//        history.push('/movie')
//      },
      onSuccess: (response) => {
        console.log("Response status:", response.status);
        console.log("Full response data:", response.data);
        console.log("Response type:", typeof response.data);
        console.log("Response keys:", Object.keys(response.data));
        console.log("Response data structure:", JSON.stringify(response.data, null, 2));

        const userInfo: UserInfo = response.data;
        // console.log("Email:", userInfo.email);
        // console.log("Role:", userInfo.role);
        // console.log("Space Name:", userInfo.space_name);
        
        // history.push('/todo')
        //history.push('/movie');
        history.push('/top');
      },
      onError: (err: any) => {
        alert(`${err.response.data.detail}\n${err.message}`)
        if (err.response.data.detail === 'The CSRF token has expired.') {
          dispatch(toggleCsrfState())
        }
      },
    }
  )
  const registerMutation = useMutation(
    async (user: User) =>
      await axios.post(`${process.env.REACT_APP_API_URL}/register`, user),
    {
      onError: (err: any) => {
        alert(`${err.response.data.detail}\n${err.message}`)
        if (err.response.data.detail === 'The CSRF token has expired.') {
          dispatch(toggleCsrfState())
        }
      },
    }
  )
  const logoutMutation = useMutation(
    async () =>
      await axios.post(
        `${process.env.REACT_APP_API_URL}/logout`,
        {},
        {
          withCredentials: true,
        }
      ),
    {
      onSuccess: () => {
        history.push('/')
      },
      onError: (err: any) => {
        alert(`${err.response.data.detail}\n${err.message}`)
        if (err.response.data.detail === 'The CSRF token has expired.') {
          dispatch(toggleCsrfState())
          dispatch(resetEditedTask())
          history.push('/')
        }
      },
    }
  )
  return { loginMutation, registerMutation, logoutMutation }
}

export default useMutateAuth