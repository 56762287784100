"use client";
import React, { useState, useEffect } from "react";
import axiosInstance from '../utils/axiosSetup';
import { AxiosError } from 'axios';

export const AuthSignUp: React.FC = () => {
    const [randomImage, setRandomImage] = useState("/top8.jpg");
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState('normal');
    const [message, setMessage] = useState('');

//    useEffect(() => {
//        const imageNumber = Math.floor(Math.random() * 8) + 1;
//        setRandomImage(`/top${imageNumber}.jpg`);
//    }, []);
    
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
        console.log(email, password, name, role);
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/register`,
        {
            email: email,
            password: password,
            name: name,
            role: role
        },
        {
          withCredentials: true
        }
      );

      setMessage('ユーザーが正常に作成されました。');

      // 成功後、ログインページにリダイレクト
      setTimeout(() => window.location.href = '/', 5000);
    } catch (error) {
        if( error instanceof AxiosError )
        {
            if (error.response)
            {
                // サーバーからのレスポンスがある場合
                setMessage((error.response.data as { detail?: string }).detail || '予期せぬエラーが発生しました。');
            }
            else if (error.request)
            {
                // リクエストは送信されたがレスポンスがない場合
                setMessage('サーバーからの応答がありません。');
            }
            else
            {
                // リクエストの設定中にエラーが発生した場合
                setMessage('リクエストの送信中にエラーが発生しました。');
            }
        } else {
            // Axiosエラー以外の場合
            setMessage('予期せぬエラーが発生しました。');
        }
        console.error('Signup error:', error);
        console.error('ユーザー作成に失敗しました:', error);
    }
  };

  return (
    <div className="flex flex-col md:flex-row h-screen font-roboto">
      <div className="flex flex-1 items-center justify-center">
        <div className="w-[400px] p-10">
          <h1 className="text-4xl font-bold mb-6">新規ユーザー登録</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm mb-2">
                メールアドレス
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="name@domain.comもしくはuser name"
                className="w-full p-2 border border-gray-300 rounded"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-sm mb-2">
                パスワード
              </label>
              <input
                type="password"
                id="password"
                name="password"
                className="w-full p-2 border border-gray-300 rounded"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm mb-2">
                名前
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="会社名など"
                className="w-full p-2 border border-gray-300 rounded"
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="role" className="block text-sm mb-2">
                役割
              </label>
              <select
                id="role"
                name="role"
                className="w-full p-2 border border-gray-300 rounded"
                onChange={(e) => setRole(e.target.value)}
                value={role}
                required
              >
                <option value="normal">クライアント</option>
                <option value="admin">管理者</option>
              </select>
            </div>
            <button
              className="w-full p-2 bg-blue-500 text-white rounded"
              type="submit"
            >
              登録
            </button>
          </form>
          {message && <p className="mt-4 text-green-600">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default AuthSignUp;