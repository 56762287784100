// 新しいページのコンポネント
import React, { useEffect, useState, useRef, useCallback  } from 'react';
//import axios from 'axios';
import axiosInstance from '../utils/axiosSetup';
import axios, { AxiosError } from 'axios';
import { useLocation } from 'react-router-dom';
import { FaPen } from 'react-icons/fa'; // FaPenのインポートを追加
import { useDropzone, FileRejection, DropEvent } from 'react-dropzone'; // react-dropzoneのインポートを追加
import MovieGenerator from './MovieGenerator';
import { MovieConfig, Part } from '../types/movie_config';
import { UserInfo } from '../types/types';
import { Project } from '../types/types';
import { useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';

const MovieCreator = () => {
  const history = useHistory();
  const [movieFiles, setMovieFiles] = useState<string[]>([]);
  const [movieConfig, setMovieConfig] = useState<MovieConfig | null>(null);
  //const [selectedOption, setSelectedOption] = useState<string>(); // 選択されたオプションを管理するための状態
  const [progress, setProgress] = useState<{ [part: string]: { [key: string]: number | null } }>({}); // partとkeyごとにprogressの情報を保持するための状態を定義
  const [imageSrc, setImageSrc] = useState<{ [key: string]: { base64: string | null, creationTime: string | null } }>({}); // 画像ソースと作成日時を管理するための状態を追加
  const [isDragActive, setIsDragActive] = useState<{ [key: string]: boolean }>({}); // ドラッグアクティブ状態を管理するための状態を追加
  const [movieFolderPath, setMovieFolderPath] = useState<string | null>(null); // 動画フォルダパスを管理するための状態を追加

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [project, setProject] = useState<Project | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const location = useLocation();
  const new_folder_name = location.pathname.split('/').pop();

  //タイトル編集
  const [edit_projectName, setEditProjectName] = useState("");
  const [edit_clientName, setEditClientName] = useState("");
  const [edit_movieTitle, setEditMovieTitle] = useState("");
  const [edit_currentConfig, setEditCurrentConfig] = useState("");

  const [isGenerating, setIsGenerating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldFetchFiles, setShouldFetchFiles] = useState(true);
  const fetchedFiles = useRef(new Set());

  // トップページへ遷移する関数
  const handleNavigateToTop = () => {
    //history.push('/top'); // ルートパス（トップページ）へ遷移
    window.location.href = '/top';
  };
  useEffect(() => {
    console.log('Current NODE_ENV:', process.env.NODE_ENV);
  }, []);

  useEffect(() => {
      console.log('Project updated:', project?.toJSON());
  }, [project]);

  useEffect(() => {
      console.log('Edit movie title updated:', edit_movieTitle);
  }, [edit_movieTitle]);

  //----------------------------->
  /* エラーメッセージ */
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const clearErrorMessage = () => {
      setErrorMessage(null);
  };
  //-----------------------------<

  const getMovieConfig = useCallback(async (option: string) => {
        try
        {
            if( userInfo )
            {
              console.log(option);
              // Movie configを取得
              const response3 = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/movie_config/${userInfo?.space_name}/${new_folder_name}/${option}`, {
                  headers: {
                      'Content-Type': 'application/json',
                  },
              });
              //const config = MovieConfig.fromJSON(response3.data.config);
                
              updateMovieConfig(response3.data);
            }
        } catch (error) {
            console.error('Movie config not found');
            setErrorMessage("動画設定の取得中にエラーが発生しました");
        }
    }, [userInfo?.space_name, new_folder_name]);

    // movieConfigの更新関数
    const updateMovieConfig = (updatedConfig: any) => {
      setMovieConfig(MovieConfig.fromJSON(updatedConfig));
  };

  const fetchAllData = useCallback(async () =>
    {
        try {
            // ユーザー情報を取得
            const userResponse = await axiosInstance.get<UserInfo>(`${process.env.REACT_APP_API_URL}/user`, {
                withCredentials: true
            });
            const currentUserInfo = userResponse.data;
            setUserInfo(currentUserInfo);

            // ロゴを取得（ユーザー情報にロゴファイル名がある場合のみ）
            if (userResponse.data.logo_filename)
            {
                const logoResponse = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/logo`, {
                    responseType: 'blob',
                    withCredentials: true
                });
                const url = URL.createObjectURL(logoResponse.data);
                setLogoUrl(url);
            }

            // プロジェクト設定を取得
            const response1 = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/project/${currentUserInfo?.space_name}/${new_folder_name}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const currentProject = response1.data;
            setProject(Project.fromJSON(currentProject));
            setEditProjectName(currentProject.project_name);
            setEditClientName(currentProject.client_name);
            setEditMovieTitle(currentProject.movie_title);
            setEditCurrentConfig(currentProject.current_config);

            // ファイル一覧を取得
            console.log(new_folder_name);
            const response2 = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/movie_folders/${currentUserInfo?.space_name}/${new_folder_name}`, {
              headers: {
                'Content-Type': 'application/json',
              },
            });
            setMovieFiles(response2.data.movie_files);

            // ここでgetMovieConfigを呼び出す
            await getMovieConfig(currentProject.current_config);

        } catch (error)
        {
          console.error('Error fetching data:', error);
          setErrorMessage("プロジェクトデータの取得中にエラーが発生しました");

        }
    }, []);

    useEffect(() => {
        fetchAllData();

        return () =>
        {
            if (logoUrl)
            {
                URL.revokeObjectURL(logoUrl);
            }
        };
    }, [fetchAllData]);

    // selectedOptionの変更を監視するuseEffect
    useEffect(() =>
    {
        if( edit_currentConfig )
        {
            console.log("edit_currentConfig:", edit_currentConfig);
            getMovieConfig(edit_currentConfig);
        }
    }, [edit_currentConfig, getMovieConfig]);

  let counter : number = 0;

  const fetchFileContent = async (fileName: string, part: string, key: string): Promise<string | null> => {
    if (fileName === "" || fetchedFiles.current.has(`${part}-${key}`)) {
      return null;
    }

    counter++;
    console.log(`fetchFileContent [${counter}] : `, fileName, part, key);

    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/movie_file/${userInfo?.space_name}/${new_folder_name}/input/${fileName}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        onDownloadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(prevProgress => ({ ...prevProgress, [part]: { ...prevProgress[part], [key]: progress } })); // ダウンロードの進捗を更新
        },
      });
      const base64Content = response.data.base64_content; // base64形式のコンテンツを取得
      const contentType = fileName.split('.').pop(); // ファイル名から拡張子を取得
      const src = `data:${contentType};base64,${base64Content}`; // Base64形式のコンテンツを返す
      const ctime = response.data.creation_time; // 作成日時を取得
      setImageSrc(prevImageSrc => ({ ...prevImageSrc, [`photo-${part}-${key}`]: { base64: src, creationTime: ctime } })); // 画像ソースと作成日時を更新
      fetchedFiles.current.add(`${part}-${key}`);
      return src;
    } catch (error) {
      console.error('File not found');
      //setErrorMessage("写真の取得に失敗しました");
      return null;
    } finally {
      setProgress(prevProgress => ({ ...prevProgress, [part]: { ...prevProgress[part], [key]: null } })); // ダウンロードが完了したら進捗バーを非表示にする
    }
  };

  // fetchFileContentが実行されていない問題を解決するために、fetchFileContentを呼び出すコードを追加
  useEffect(() => {
    if (movieConfig && shouldFetchFiles) {
      setIsLoading(true);
      const promises: Promise<string | null>[] = [];
      Object.keys(movieConfig).forEach(part => {
        Object.keys((movieConfig as any)[part].photo_list || {}).forEach(key => {
          const fileName = (movieConfig as any)[part].photo_list[key];
          if (fileName && (!imageSrc[`photo-${part}-${key}`] || imageSrc[`photo-${part}-${key}`].base64 === null)) {
            promises.push(fetchFileContent(fileName, part, key));
          }
        });
      });
      Promise.all(promises).finally(() => {
        setIsLoading(false);
        setShouldFetchFiles(false);
      });
    }
  }, [movieConfig, shouldFetchFiles]);

  // movieConfigが更新されたときにfetchを再度許可する
  useEffect(() => {
    setShouldFetchFiles(true);
    fetchedFiles.current.clear();
  }, [movieConfig]);

  const handleUploadPhoto = async (part: string, key: string) => {
    const fileInput = document.createElement('input');
    fileInput.setAttribute('type', 'file');
    fileInput.setAttribute('accept', '.png,.jpg,.jpeg,.mp4,.mov');
    //fileInput.setAttribute('accept', 'image/*');
    fileInput.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file)
      {
        const validTypes = ['image/png', 'image/jpeg', 'video/mp4', 'video/quicktime'];
        if (!validTypes.includes(file.type)) {
          alert('無効なファイル形式です。PNG、JPG、JPEG、MP4、MOV ファイルのみアップロード可能です。');
          return;
        }
        
        const reader = new FileReader();
        reader.onload = async (e) => {
          const result = e.target?.result; // 'e.target' is possibly 'null'の問題を解決するために、オプショナルチェーンを使用
          if (result) {
            console.log(`File dropped: ${file.name}`); // ドラッグ＆ドロップが完了したらファイル名を表示
            
            // ファイルをサーバーにアップロード
            try {
              const base64Result = await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                  const base64 = reader.result?.toString().split(',')[1];
                  if (base64) {
                    resolve(base64);
                  } else {
                    reject(new Error('Base64 encoding failed'));
                  }
                };
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file);
              });

              const response = await fetch(`${process.env.REACT_APP_API_URL}/movie_file/${userInfo?.space_name}/${new_folder_name}/input/${file.name}?part=${part}&key=${key}&config_file_name=${edit_currentConfig}`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({ base64: base64Result }),
              });

              if (!response.ok) {
                throw new Error('Failed to upload file');
                setErrorMessage("ファイルのアップロードに失敗しました");
              }

              const uploadResult = await response.json();
              console.log(uploadResult.message); // アップロード成功メッセージをコンソールに出力

              setImageSrc(prev => {
                const newImageSrc = { ...prev, [`photo-${part}-${key}`]: { base64: null, creationTime: null } };
                return newImageSrc;
              });

              // ここでgetMovieConfigを呼び出す
              await getMovieConfig(edit_currentConfig);
              fetchProjectInfo();
            } catch (error) {
              if (axios.isAxiosError(error) && error.response?.status === 413) {
                  setErrorMessage('画像サイズが大きすぎます。100MBより小さい画像を選択してください。');
              }            

              console.error('Failed to upload file', error); // アップロード失敗時のエラーをコンソールに出力
              setErrorMessage("ファイルのアップロード中にエラーが発生しました");
            }
          }
        };
        reader.readAsDataURL(file);
      }
    };
    fileInput.click();
  };

  const handleDrop = async (acceptedFiles: File[], fileRejections: FileRejection[], event: DropEvent) => {
    const divElement = event.currentTarget as HTMLElement;
    if (!divElement) {
      console.error('Div element not found');
      return;
    }
    const divId = divElement.id; // divのidを取得
    // divIdがphoto-front-1ならpart=front, key=1
    const part = divId.split('-')[1];
    const key = divId.split('-')[2];

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const validTypes = ['image/png', 'image/jpeg', 'video/mp4'];
      if (!validTypes.includes(file.type)) {
        alert('無効なファイル形式です。PNG、JPG、JPEG、MP4 ファイルのみアップロード可能です。');
        return;
      }
            
      const reader = new FileReader();
      reader.onload = async (e) => {
        const result = e.target?.result; // 'e.target' is possibly 'null'の問題を解決するために、オプショナルチェーンを使用
        if (result) {
          console.log(`File dropped: ${file.name}`); // ドラッグ＆ドロップが完了したらファイル名を表示
          console.log(`Dropped div id: ${divId}`); // 取得したdivのidをコンソールに出力

          // ファイルをサーバーにアップロード
          try {
            const base64Result = await new Promise<string>((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                const base64 = reader.result?.toString().split(',')[1];
                if (base64) {
                  resolve(base64);
                } else {
                  reject(new Error('Base64 encoding failed'));
                }
              };
              reader.onerror = error => reject(error);
              reader.readAsDataURL(file);
            });

            const response = await fetch(`${process.env.REACT_APP_API_URL}/movie_file/${userInfo?.space_name}/${new_folder_name}/input/${file.name}?part=${part}&key=${key}&config_file_name=${edit_currentConfig}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ base64: base64Result }),
            });

            if (!response.ok) {
              throw new Error('Failed to upload file');
              setErrorMessage("ファイルのアップロードに失敗しました");
            }

            const uploadResult = await response.json();
            console.log(uploadResult.message); // アップロード成功メッセージをコンソールに出力


            setImageSrc(prev => {
              const newImageSrc = { ...prev, [`photo-${part}-${key}`]: { base64: null, creationTime: null } };
              return newImageSrc;
            });

            // ここでgetMovieConfigを呼び出す
            await getMovieConfig(edit_currentConfig);
            fetchProjectInfo();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 413) {
                setErrorMessage('画像サイズが大きすぎます。100MBより小さい画像を選択してください。');
            }

            console.error('Failed to upload file', error); // アップロード失敗時のエラーをコンソールに出力
            setErrorMessage("ファイルのアップロード中にエラーが発生しました");
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ 
    onDrop: (acceptedFiles, fileRejections, event) => handleDrop(acceptedFiles, fileRejections, event),
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'video/mp4': ['.mp4'],
      'video/quicktime': ['.mov']
    },
    noClick: true, // クリックでのファイル選択を無効化
  });

  const handleGenerateMovie = async () => {
    try {
      // 既存の動画ファイルを削除
      await axiosInstance.post(`${process.env.REACT_APP_API_URL}/delete_movie/${userInfo?.space_name}/${new_folder_name}/${edit_currentConfig}`);

      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/generate_movie_local/${userInfo?.space_name}/${new_folder_name}/${edit_currentConfig}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setMovieFolderPath(response.data.movie_folder_path);
    } catch (error) {
      console.error('動画生成に失敗しました:', error);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.data) {
          console.error('エラーレスポンス:', axiosError.response.data);
          setErrorMessage(`動画生成中にエラーが発生しました: ${(axiosError.response.data as any).message || '不明なエラー'}`);
        } else {
          setErrorMessage('動画生成中にエラーが発生しました: レスポンスデータがありません');
        }
      } else {
        setErrorMessage('動画生成中に予期せぬエラーが発生しました');
      }
    }
  };

  //案件名編集
  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditProjectName(e.target.value);
  };

  const handleProjectNameBlur = async () => {
    if (edit_projectName === project?.project_name) {
      return;
    }

    try {
        // APIを呼び出してプロジェクト名を更新
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update_project_info/${userInfo?.space_name}/${new_folder_name}`, {
            key: "project_name", 
            part: edit_projectName,
        });
        console.log('Project name updated successfully');
        const currentProject = response.data;
        setProject(Project.fromJSON(currentProject));
    } catch (error) {
        console.error('プロジェクト名の更新に失敗しました:', error);
        setErrorMessage("プロジェクト名の更新中にエラーが発生しました");
        // エラー処理（例：エラーメッセージを表示）
        setEditProjectName(project?.project_name || "");
    }
  };

  //顧客名編集
  const handleClientNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditClientName(e.target.value);
  };

  const handleClientNameBlur = async () => {
    if (edit_clientName === project?.client_name) {
      return;
    }

    try {
        // APIを呼び出してプロジェクト名を更新
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update_project_info/${userInfo?.space_name}/${new_folder_name}`, {
            key: "client_name", 
            part: edit_clientName,
        });
        console.log('Client name updated successfully');
        const currentProject = response.data;
        setProject(Project.fromJSON(currentProject));
        
        // ここでgetMovieConfigを呼び出す
        await getMovieConfig(currentProject.current_config);
    } catch (error) {
        console.error('顧客名の更新に失敗しました:', error);
        // エラー処理（例：エラーメッセージを表示）
        setEditClientName(project?.client_name || "");
        setErrorMessage("顧客名の更新中にエラーが発生しました");
    }
  };

  //動画タイトル編集
  const handleMovieTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditMovieTitle(e.target.value);
  };

  const handleMovieTitleBlur = async () => {
    if (edit_movieTitle === project?.movie_title) {
      return;
    }

    try {
        // APIを呼び出してプロジェクト名を更新
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update_project_info/${userInfo?.space_name}/${new_folder_name}`, {
            key: "movie_title", 
            part: edit_movieTitle,
        });
        console.log('Movie title updated successfully');
        const currentProject = response.data;
        
        setProject(Project.fromJSON(currentProject));
        setEditCurrentConfig(currentProject.current_config);
        
        // ここでgetMovieConfigを呼び出す
        await getMovieConfig(currentProject.current_config);
    } catch (error) {
        console.error('動画タイトルの更新に失敗しました:', error);
        // エラー処理（例：エラーメッセージを表示）
        setEditMovieTitle(project?.movie_title || "");
        setErrorMessage("動画タイトルの更新中にエラーが発生しました");
    }
  };

  //Configファイル編集
  const handleCurrentConfigChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

    console.log(project?.current_config, e.target.value);
    setEditCurrentConfig(e.target.value);
    if (e.target.value === project?.current_config) {
      console.log("Configファイルが変更されていません");
      return;
    }

    try {
        // APIを呼び出してプロジェクト名を更新
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update_project_info/${userInfo?.space_name}/${new_folder_name}`, {
            key: "current_config", 
            part: e.target.value,
        });
        console.log('Current config updated successfully');
        const currentProject = response.data;
        //setProject(Project.fromJSON(currentProject));
        setProject(Project.fromJSON(currentProject));

        setEditProjectName(currentProject.project_name);
        setEditClientName(currentProject.client_name);
        setEditMovieTitle(currentProject.movie_title);
        //setEditCurrentConfig(currentProject.current_config);

        if (imageSrc && Object.keys(imageSrc).length > 0) {
          // imageSrcの各値（URL）をクリア
          Object.values(imageSrc).forEach(({ base64 }) => {
            if (base64) {
              //console.log("revoke imageSrc: ", base64);
              URL.revokeObjectURL(base64);
            }
          });
          
          // imageSrcを空のオブジェクトにリセット
          setImageSrc({});
        }
        
        // ここでgetMovieConfigを呼び出す
        await getMovieConfig(currentProject.current_config);
    } catch (error) {
        console.error('Configファイルの更新に失敗しました:', error);
        // エラー処理（例：エラーメッセージを表示）
        //setEditCurrentConfig(project?.current_config || "");
        setErrorMessage("Configファイルの更新中にエラーが発生しました");
    }
  };

    const [editTitle, setEditTitle] = useState<{ [key: string]: string }>({});

    const handleTitleChange = (part: string, newTitle: string) => {
        setEditTitle(prev => ({ ...prev, [part]: newTitle }));
    };

    const handleTitleBlur = async (part: string, newTitle: string) => {
        // 現在のタイトルを取得
        const currentTitle = movieConfig?.[part]?.title_list.title;

        console.log(currentTitle, newTitle);

        // 新しいタイトルが現在のタイトルと同じ場合は処理を行わない
        if (newTitle === currentTitle) {
            // 編集値をクリア
            setEditTitle(prev => {
                const newValues = { ...prev };
                delete newValues[part];
                return newValues;
            });
            return;
        }
        try {
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update_title/${userInfo?.space_name}/${new_folder_name}`, {
                part: part,
                newTitle: newTitle,
                config_file_name: edit_currentConfig
            });
            console.log('Title updated successfully');
            updateMovieConfig(response.data);
            // 編集値をクリア
            setEditTitle(prev => {
                const newValues = { ...prev };
                delete newValues[part];
                return newValues;
            });
        } catch (error) {
            console.error('Title update failed:', error);
            setErrorMessage("タイトルの更新中にエラーが発生しました");
        }
    };

  const newRoleRef = useRef<HTMLInputElement>(null);
  const newNameRef = useRef<HTMLInputElement>(null);
  const [editValues, setEditValues] = useState<{ [key: string]: string }>({});

  const handleMessageChange = (part: string, key: string, outerIndex: number, innerIndex: number, newValue: string) => {
      const editKey = `${part}-${key}-${outerIndex}-${innerIndex}`;
      setEditValues(prevValues => ({
          ...prevValues,
          [editKey]: newValue
      }));
  };

  const handleMessageBlur = async (part: string, key: string, outerIndex: number, innerIndex: number, newValue: string) => {
    const currentValue = movieConfig?.[part]?.message_list[key]?.[outerIndex]?.[innerIndex];
    if (newValue === currentValue) {
        // 編集値をクリア
        const editKey = `${part}-${key}-${outerIndex}-${innerIndex}`;
        setEditValues(prevValues => {
            const newValues = { ...prevValues };
            delete newValues[editKey];
            return newValues;
        });
        return;
    }

    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update_message/${userInfo?.space_name}/${new_folder_name}`, {
            part: part,
            key: key, 
            outerIndex: outerIndex,
            innerIndex: innerIndex,
            newValue: newValue,
            config_file_name: edit_currentConfig
        });
        console.log('Message updated successfully');
        // APIからの応答でmovieConfigを更新
        updateMovieConfig(response.data);
        // 編集値をクリア
        const editKey = `${part}-${key}-${outerIndex}-${innerIndex}`;
        setEditValues(prevValues => {
            const newValues = { ...prevValues };
            delete newValues[editKey];
            return newValues;
        });
    } catch (error) {
        console.error('Message update failed:', error);
        setErrorMessage("メッセージの更新中にエラーが発生しました");
    }
  };

  const handleRemoveRow = async (part: string, key: string, index: number) => {
    try {
        const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/remove_message_endroll/${userInfo?.space_name}/${new_folder_name}`, {
            part: part,
            key: key,
            index: index,
            config_file_name: edit_currentConfig
        });
        console.log('Message removed successfully');
        // APIからの応答でmovieConfigを更新
        updateMovieConfig(response.data);
    } catch (error) {
        console.error('Failed to remove message:', error);
        setErrorMessage("メッセージの削除中にエラーが発生しました");
    }
  };

  const handleAddRow = async (part: string) => {
  const newRole = newRoleRef.current?.value;
  const newName = newNameRef.current?.value;
  if (newRole && newName) {
      try {
          const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/add_message_endroll/${userInfo?.space_name}/${new_folder_name}`, {
              part: part,
              newValue: [newRole, newName],
              config_file_name: edit_currentConfig
          });
          
          // 返されたconfigデータでmovieConfigを更新
          updateMovieConfig(response.data);
          
          console.log('Message added successfully');
          
          // 入力フィールドをクリア
          if (newRoleRef.current) newRoleRef.current.value = '';
          if (newNameRef.current) newNameRef.current.value = '';
      } catch (error) {
          console.error('Failed to add message:', error);
          setErrorMessage("メッセージの追加中にエラーが発生しました");
      }
  } else {
      console.error('New role or name not found');
      setErrorMessage("両方の項目を入力してください");
  }
  };

  const handleAddPhoto = async (part: string, key: string) => {
      console.log('Add photo:', part, key);
      try
      {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/movie_samplefile/${userInfo?.space_name}/${new_folder_name}?part=${part}&key=${key}&config_file_name=${edit_currentConfig}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Faile set sample file');
        setErrorMessage("サンプルファイルのセットに失敗しました");
      }

      const uploadResult = await response.json();
      console.log(uploadResult.message);

      // ここでgetMovieConfigを呼び出す
      await getMovieConfig(edit_currentConfig);
      fetchProjectInfo();
    } catch (error) {
      console.error('Failed to upload file', error);
      setErrorMessage("サンプルファイルのセット中にエラーが発生しました");
    }

  };

  const handleRemovePhoto = async (part: string, key: string) => {
    try {
      console.log('Removing photo:', part, key);
      const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/remove_photo/${userInfo?.space_name}/${new_folder_name}`, {
        part: part,
        key: key,
        config_file_name: edit_currentConfig
      });
  
      if (response.status === 200) {
        console.log('Photo removed successfully');
        // 更新されたmovieConfigを設定
        updateMovieConfig(response.data);
        
        // imageSrcから削除された写真を削除
        setImageSrc(prev => {
          const newImageSrc = { ...prev, [`photo-${part}-${key}`]: { base64: null, creationTime: null } };
          return newImageSrc;
        });
  
        // 成功メッセージを表示
        console.log("写真が正常に削除されました");

        fetchProjectInfo();
      } else {
        throw new Error('Failed to remove photo');
        setErrorMessage("写真の削除に失敗しました");
      }
    } catch (error) {
      console.error('Failed to remove photo:', error);
      setErrorMessage("写真の削除中にエラーが発生しました");
    }
  };

  const fetchProjectInfo = useCallback(async () => {
    console.log('fetchProjectInfo called');  // 関数呼び出しの確認
    try {
      if (!userInfo?.space_name) {
        console.log('userInfo is not available');
        return;
      }
      
      if (!new_folder_name) {
        console.log('new_folder_name is not available');
        return;
      }
      
      console.log(`Fetching project for ${userInfo.space_name}/${new_folder_name}`);  // リクエスト前のログ
      
      // プロジェクト設定を取得
      const response1 = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/project/${userInfo.space_name}/${new_folder_name}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      console.log('API response received');  // レスポンス受信のログ
      
      const currentProject = response1.data;
      setProject(Project.fromJSON(currentProject));
  
      console.log('Project fetched successfully', currentProject);  // 成功時のログ（プロジェクトデータも表示）
    } catch (error) {
      console.error('Error fetching projects:', error);
      setErrorMessage('プロジェクトの取得に失敗しました。システム管理者へ連絡してください。');
    }
  }, [userInfo?.space_name, new_folder_name]);

  const handleStartMovieGenerate = useCallback(() => {
    setIsGenerating(true);
  }, []);

  const handleMovieGenerated = useCallback(() => {
    setIsGenerating(false);
    
    console.log('handleMovieGenerated triggered, calling fetchProjectInfo');
    // プロジェクト情報を再取得する
    fetchProjectInfo();
  }, [fetchProjectInfo]);

    //----------------------------->
    /* 動画再生モーダルウィンドウ */
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");

    const openModal = (space_name: string, movie_folder: string) => {
        if(space_name === "" || movie_folder === "")
        {
            return;
        }
        
        const videoUrl = `${process.env.REACT_APP_API_URL}/movie_output/${space_name}/${movie_folder}`;
        setSelectedVideoUrl(videoUrl);
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setSelectedVideoUrl("");
      setIsModalOpen(false);
    };
    //-----------------------------<

    //----------------------------->
    /* ダウンロード */
    const handleDownload = async (spaceName: string, movieFolder: string) => {
      try {
          const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/movie_output/${spaceName}/${movieFolder}`, {
              responseType: 'blob',
          });
      // 現在の日時を取得
      const now = new Date();
      const dateString = now.getFullYear() +
                         ('0' + (now.getMonth() + 1)).slice(-2) +
                         ('0' + now.getDate()).slice(-2) + '_' +
                         ('0' + now.getHours()).slice(-2) +
                         ('0' + now.getMinutes()).slice(-2) +
                         ('0' + now.getSeconds()).slice(-2);

      // ファイル名を生成
      const fileName = `${movieFolder}_${dateString}.mp4`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
      } catch (error) {
          console.error('動画のダウンロードに失敗しました:', error);
          setErrorMessage('動画のダウンロードに失敗しました。システム管理者へ連絡してください。');
      }
  };
  //-----------------------------<

  if (!userInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4 relative">
      {isLoading && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-50 z-50 flex items-start justify-center">
        <div id="loading-box" className="bg-white p-4 rounded-lg shadow-lg mt-64 flex flex-col items-center">
          <p className="text-lg font-semibold mb-2">ファイルを読み込み中...</p>
          <div className="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
      </div>
      )}
            <header className="mb-3 my-8">
            <div className="flex justify-between items-end">
                <div className="w-[70%]">
                    <label className="block font-semibold mb-2">案件名</label>
                    <input
                        name="projectName"
                        className="border border-gray-300 p-2 w-full"
                        type="text"
                        value={edit_projectName}
                        onChange={handleProjectNameChange}
                        onBlur={handleProjectNameBlur}/>
                </div>
                <button className="bg-blue-400 text-white px-5 py-2 rounded transition duration-300 ease-in-out hover:bg-blue-500" 
                        onClick={handleNavigateToTop}>
                    Topへ戻る
                </button>
            </div>
        </header>
        <div className="mb-6">
            <label className="block mb-2 font-semibold">依頼主</label>
            <input
                name="clientName"
                className="border border-gray-300 p-2 w-full mb-4"
                type="text"
                value={edit_clientName}
                onChange={handleClientNameChange}
                onBlur={handleClientNameBlur}
            />
            <label className="block mb-2 font-semibold">動画タイトル</label>
            <input
                name="videoTitle"
                className="border border-gray-300 p-2 w-full"
                type="text"
                value={edit_movieTitle}
                onChange={handleMovieTitleChange}
                onBlur={handleMovieTitleBlur}
            />
        </div>
        <div className="mb-4">
            <div>
                <input type="radio" id="movie_a_config.json" name="options" value="movie_a_config.json" checked={edit_currentConfig === 'movie_a_config.json'} onChange={handleCurrentConfigChange} />
                <label htmlFor="movie_a_config.json" className="ml-2">楽曲A：新築向け　　　　4分30秒　総写真数 48枚</label>
            </div>
            <div>
                <input type="radio" id="movie_b_config.json" name="options" value="movie_b_config.json" checked={edit_currentConfig === 'movie_b_config.json'} onChange={handleCurrentConfigChange} />
                <label htmlFor="movie_b_config.json" className="ml-2">楽曲B：新築向け　　　　4分30秒　総写真数 48枚</label>
            </div>
            <div>
                <input type="radio" id="movie_c_config.json" name="options" value="movie_c_config.json" checked={edit_currentConfig === 'movie_c_config.json'} onChange={handleCurrentConfigChange} />
                <label htmlFor="movie_c_config.json" className="ml-2">楽曲C：リノベーション向け　4分00秒　総写真数 34枚</label>
            </div>
            <div>
                <input type="radio" id="movie_d_config.json" name="options" value="movie_d_config.json" checked={edit_currentConfig === 'movie_d_config.json'} onChange={handleCurrentConfigChange} />
                <label htmlFor="movie_d_config.json" className="ml-2">楽曲D：SNS向け　　　　0分30秒　総写真数 12枚</label>
            </div>
        </div>
        {movieFiles.length > 0 ? (
            <div className="mb-4">
                {/* <h1 className="text-xl font-bold">Movie Files</h1>
                <ul>
                    {movieFiles.map((file, index) => (
                        <li key={index} className="mb-1">{file}</li>
                    ))}
                </ul> */}
            </div>
        ) : (
            <h1>Movie files not found</h1>
        )}
        {movieConfig && (
            <div>
                {/* <h1>Movie Config</h1>
                <pre>{JSON.stringify(movieConfig, null, 2)}</pre> */}
            </div>
        )}
        {movieConfig && (
            <div className="text-lg font-bold w-full">
                {Object.keys(movieConfig).map((part: string) => {
                    const currentPart = movieConfig.getPart(parseInt(part.replace('part', '')));
                    if (!currentPart) return null;
                    return (
                        <div key={part} className="mb-10">
                            <hr className="my-2 border-t-4 border-dotted border-gray-400 w-full" />

                            <div className="text-lg font-semibold flex justify-between items-center mb-4">
                                {currentPart.title_list.is_enable ? (
                                  <input
                                      value={editTitle[part] ?? currentPart.title_list.title}
                                      onChange={(e) => handleTitleChange(part, e.target.value)}
                                      onBlur={(e) => handleTitleBlur(part, e.target.value)}
                                      className="border rounded-md p-2 w-full hover:bg-stone-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-stone-300 transition duration-150 ease-in-out"
                                  />
                                ) : (
                                  <div style={{ position: 'relative', display: 'inline-block', paddingRight: '20px' }}>
                                    <span style={{ paddingLeft: '20px' }}>
                                      {currentPart.title_list.title || ''}
                                    </span>
                                    <span style={{
                                      position: 'absolute',
                                      bottom: '-4px',
                                      left: '0',
                                      right: '0',
                                      borderBottom: '2px solid #718096'
                                    }}></span>
                                  </div>
                                )}
                            </div>
                            
                            <div>
                                {Object.keys(currentPart.photo_list).length > 0 && (
                                    <div className="flex flex-wrap">
                                        {Object.keys(currentPart.photo_list).map((key) => (
                                            !currentPart.message_list[key] && (
                                                <div 
                                                    key={key} 
                                                    id={`photo-${part}-${key}`} 
                                                    className={`
                                                        w-[300px] h-[250px] 
                                                        bg-white shadow-md rounded-lg overflow-hidden font-roboto m-2 relative 
                                                        ${isDragActive[`photo-${part}-${key}`] ? 'border-2 border-dashed border-blue-500' : ''}
                                                        ${currentPart.point_list && currentPart.point_list.includes(parseInt(key)) ? 'border-4 border-red-500' : ''}
                                                    `} 
                                                    {...getRootProps({
                                                        onDragOver: (e) => {
                                                        e.preventDefault();
                                                        setIsDragActive(prevIsDragActive => ({ ...prevIsDragActive, [`photo-${part}-${key}`]: true })); 
                                                    }, 
                                                    onDragLeave: () => {setIsDragActive(prevIsDragActive => ({ ...prevIsDragActive, [`photo-${part}-${key}`]: false }))},
                                                    onDrop: (e) => {
                                                        e.preventDefault();
                                                        setIsDragActive(prevIsDragActive => ({ ...prevIsDragActive, [`photo-${part}-${key}`]: false }));
                                                        handleDrop(Array.from(e.dataTransfer.files), [], e) }})}>
                                                    <input {...getInputProps()} onClick={(e) => e.stopPropagation()} />
                                                    <div className="h-[60%] bg-gray-200 flex items-center justify-center cursor-pointer" >
                                                        {imageSrc[`photo-${part}-${key}`]?.base64 ? (
                                                            <img src={imageSrc[`photo-${part}-${key}`]?.base64 || ''} alt={key} className="h-full w-full object-cover" />
                                                        ) : (
                                                            <div className="loader"></div>
                                                        )}
                                                        {!imageSrc[`photo-${part}-${key}`]?.base64 && (
                                                            <div className="flex flex-col items-center justify-center">
                                                                <button 
                                                                    className="mb-2 text-center text-gray-500 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded cursor-pointer"
                                                                    onClick={() => handleUploadPhoto(part, key)}
                                                                >
                                                                    写真を選択してください
                                                                </button>
                                                                <span className="text-center text-gray-500">
                                                                    もしくは<br />
                                                                    ドラッグ＆ドロップ
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="h-[30%] bg-white p-4 flex">
                                                        <div className="flex flex-col w-[85%]">
                                                            <span 
                                                                className="block whitespace-nowrap overflow-hidden text-ellipsis flex-shrink-0"
                                                                title={currentPart.photo_list[key]}
                                                                >
                                                                {currentPart.photo_list[key]}
                                                            </span>
                                                            <span className="text-sm">
                                                                撮影日：
                                                                {imageSrc[`photo-${part}-${key}`]?.creationTime 
                                                                    ? imageSrc[`photo-${part}-${key}`]?.creationTime 
                                                                    : '----.--.-- 00:00'}
                                                            </span>
                                                            <span className="text-sm text-gray-500">{part}, {key}</span>
                                                        </div>
                                                        <div className="flex flex-col w-[15%] justify-between">
                                                            <button
                                                                className={`bg-transparent border-none cursor-pointer p-2 rounded transition duration-300 ease-in-out hover:bg-gray-300 ${
                                                                    imageSrc[`photo-${part}-${key}`]?.base64 ? 'opacity-50 cursor-not-allowed' : ''
                                                                }`}
                                                                onClick={() => handleAddPhoto(part, key)}
                                                                disabled={!!imageSrc[`photo-${part}-${key}`]?.base64}
                                                            >
                                                                <img 
                                                                    src="/add_blue.svg" 
                                                                    alt="Add" 
                                                                    className={`w-6 h-6 ${imageSrc[`photo-${part}-${key}`]?.base64 ? 'opacity-50' : ''}`} 
                                                                />
                                                            </button>
                                                            <button
                                                                className={`bg-tranceparent border-none cursor-pointer p-2 rounded transition duration-300 ease-in-out transition duration-300 ease-in-out hover:bg-gray-300" ${
                                                                  imageSrc[`photo-${part}-${key}`]?.base64 ? '' : 'opacity-50'
                                                                }`}
                                                                onClick={() => handleRemovePhoto(part, key)}
                                                                disabled={!imageSrc[`photo-${part}-${key}`]?.base64}
                                                                >
                                                                <img src="/trash_icon.svg" alt="Delete" className="w-6 h-6" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="h-[10%] bg-white p-4">
                                                        {progress[part]?.[key] && (
                                                            <div className="progress-bar w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                                                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress[part][key]}%` }}></div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-row">
                                <div className="flex-1">
                                    {Object.keys(currentPart.message_list).length > 0 && (
                                        <div className="flex flex-col">
                                            {Object.keys(currentPart.message_list).map((key) => (
                                                <div key={key} className="w-[622px] h-[350px] shadow-md rounded-lg overflow-hidden font-roboto m-2" 
                                                      style={{ color: ['black', 'ending', 'end', 'endroll'].includes(currentPart.effect_list[key]) ? 'white' : 'black' }}>
                                                    <div className="h-full flex flex-col items-center justify-center" style={{ backgroundImage: `url(${imageSrc[`photo-${part}-${key}`]?.base64 || ''})`, backgroundSize: 'cover' }}>
                                                        <span className="text-gray-500 text-center">
                                                            {(currentPart.message_list[key] || []).map((messageGroup: string[], groupIndex: number) => (
                                                              <p key={groupIndex} style={{ 
                                                                    color: ['black', 'ending', 'end', 'endroll'].includes(currentPart.effect_list[key]) ? 'white' : 'black', 
                                                                    whiteSpace: 'pre-wrap',
                                                                    fontSize: currentPart.effect_list[key] === "endroll" ? '20px' : 
                                                                        currentPart.effect_list[key] === "ending" ? '14px' : '24px',
                                                                    lineHeight: '1.2' 
                                                                }}>
                                                                    {currentPart.effect_list[key] === "endroll"
                                                                        ? `${messageGroup[0]} : ${messageGroup[1]}`
                                                                        : messageGroup.join('')}
                                                                </p>
                                                            ))}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {!Object.values(currentPart.effect_list).includes("endroll") && (Object.keys(currentPart.message_list).length > 0) && (
                                    <div className="flex-1">
                                      <div className="p-4 max-w-[800px] mx-auto">
                                        {Object.entries(currentPart.message_list).map(([key, messageGroups]) => (
                                          <div key={key} className={`mb-4 ${parseInt(key) > 1 ? 'mt-20' : ''}`}>
                                            <h5 className="font-semibold mb-2">{currentPart.title_list.title || 'タイトルなし'}メッセージ {key}</h5>
                                            {messageGroups.map((messageGroup, groupIndex) => (
                                              <div key={`${key}-${groupIndex}`} className="flex flex-wrap mb-0">
                                                {messageGroup.map((message, messageIndex) => (
                                                  <input
                                                    key={`${key}-${groupIndex}-${messageIndex}`}
                                                    value={editValues[`${part}-${key}-${groupIndex}-${messageIndex}`] ?? message}
                                                    onChange={(e) => handleMessageChange(part, key, groupIndex, messageIndex, e.target.value)}
                                                    onBlur={(e) => handleMessageBlur(part, key, groupIndex, messageIndex, e.target.value)}
                                                    className="flex-1 border rounded-md p-1 mr-2 mb-1 min-w-[100px] hover:bg-stone-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-stone-300 transition duration-150 ease-in-out"
                                                  />
                                                ))}
                                              </div>
                                            ))}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                  {Object.values(currentPart.effect_list).includes("endroll") && (
                                    <div className="flex-1">
                                        <div className="p-4 max-w-[800px] mx-auto">
                                            <div className="grid grid-cols-12 gap-4 mb-4">
                                            <div className="col-span-5 font-bold">役職</div>
                                            <div className="col-span-5 font-bold">名前</div>
                                        </div>
                                        {Object.entries(currentPart.message_list).map(([key, valueArray]) => (
                                            <div key={key}>
                                                {valueArray.map((value, outerIndex) => (
                                                    <div key={`${key}-${outerIndex}`} className="grid grid-cols-10 gap-4 mb-4 items-center">
                                                        <input
                                                            name={`role-${key}-${outerIndex}`}
                                                            value={editValues[`${part}-${key}-${outerIndex}-0`] ?? value[0]}
                                                            onChange={(e) => handleMessageChange(part, key, outerIndex, 0, e.target.value)}
                                                            onBlur={(e) => handleMessageBlur(part, key, outerIndex, 0, e.target.value)}
                                                            className="col-span-3 border rounded-md p-1 hover:bg-stone-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-stone-300 transition duration-150 ease-in-out"
                                                        />
                                                        <input
                                                            name={`name-${key}-${outerIndex}`}
                                                            value={editValues[`${part}-${key}-${outerIndex}-1`] ?? value[1]}
                                                            onChange={(e) => handleMessageChange(part, key, outerIndex, 1, e.target.value)}
                                                            onBlur={(e) => handleMessageBlur(part, key, outerIndex, 1, e.target.value)}
                                                            className="col-span-5 border rounded-md p-1 hover:bg-stone-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-stone-300 transition duration-150 ease-in-out"
                                                        />
                                                        <button 
                                                            onClick={() => handleRemoveRow(part, key, outerIndex)}
                                                            className="col-span-2 text-red-500 text-2xl"
                                                        >
                                                            <img src="/delete_circle_red.svg" alt="Delete" className="w-6 h-6" />
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                      <div className="grid grid-cols-10 gap-4 items-center">
                                          <input
                                              name="new-role"
                                              className="col-span-3 border rounded-md p-1 hover:bg-stone-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-stone-300 transition duration-150 ease-in-out"
                                              ref={newRoleRef}
                                          />
                                          <input
                                              name="new-name"
                                              className="col-span-5 border rounded-md p-1 hover:bg-stone-100 focus:bg-white focus:outline-none focus:ring-2 focus:ring-stone-300 transition duration-150 ease-in-out"
                                              ref={newNameRef}
                                          />
                                          <button
                                              onClick={(e) => handleAddRow(part)}
                                              className="col-span-2 bg-blue-500 rounded-md text-white p-2 flex items-center justify-center"
                                          >
                                              <img src="/add_circle_white.svg" alt="Add" className="w-4 h-4 mr-1" />
                                              <span className="text-xs">追加</span>
                                          </button>
                                      </div>
                                    </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
        )}
       <hr className="my-2 border-t-4 border-dotted border-gray-400 w-full" />
       {process.env.NODE_ENV === 'development' && (
          <div className="flex mt-4">
            <button onClick={handleGenerateMovie} className="w-[400px] bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                動画生成
            </button>
          </div>
        )}
        {edit_currentConfig && new_folder_name && (
          <div className="flex mt-4">
            {project && project.progress === project.totalPhotos ? (
              <MovieGenerator 
                spaceId={userInfo?.space_name} 
                folderId={new_folder_name} 
                configFileName={edit_currentConfig} 
                isGenerating={!!project?.generate_task_id && project.generate_task_id !== ''}
                task_id={project?.generate_task_id && project.generate_task_id !== '' ? project.generate_task_id : null}
                onStartMovieGenerate={handleStartMovieGenerate}
                onMovieGenerated={handleMovieGenerated}
              />
            ) : (
              <span>
                すべての項目に写真をセットすると動画が生成できます。
              </span>
            )}
          </div>
        )}
        {!isGenerating && (
          <div className="flex mt-4">
            {project?.output ? (
              <>
                <div className="flex items-center mb-2 sm:mb-0 max-w-[80%]">
                  <button className="w-[300px] mr-10 bg-green-100 text-black text-lg font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out hover:bg-green-300" 
                          onClick={(e) => {e.stopPropagation(); openModal(userInfo?.space_name || '', project.movie_folder)}}>
                    <img src={`${process.env.PUBLIC_URL}/play-gray.svg`} alt="Play" className="w-6 h-6 mr-2" />
                    再生
                  </button>
                  <button className="w-[300px] mr-2 bg-blue-100 text-black text-lg font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out hover:bg-blue-300" 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDownload(userInfo?.space_name || '', project.movie_folder);
                          }}>
                    <img src={`${process.env.PUBLIC_URL}/download_icon.svg`} alt="DL" className="w-6 h-6 mr-2" />
                    ダウンロード
                  </button>
                </div>
              </>
            ) : (
              <span className="text-red-500"></span>
            )}
          </div>
        )}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
              <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={closeModal}></div>
              <div className="bg-white p-4 rounded-lg z-10 w-4/5 max-h-[80vh] flex flex-col">
                  <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                      <video 
                          src={selectedVideoUrl} 
                          controls 
                          autoPlay 
                          className="absolute top-0 left-0 w-full h-full object-contain"
                      ></video>
                  </div>
                  <button 
                      onClick={closeModal} 
                      className="mt-4 px-4 py-2 bg-gray-200 rounded self-end hover:bg-gray-300 transition-colors"
                  >
                      閉じる
                  </button>
              </div>
          </div>
        )}
    </div>
    );
};

export default MovieCreator;