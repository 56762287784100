import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

let isRedirecting = false;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const status = error.response.status;
      //if ((status === 401 || status === 422 || status === 400) && !isRedirecting) {
      if ((status === 401 || status === 422) && !isRedirecting) {
        isRedirecting = true;
        // Cookieを削除する処理
        document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // ログイン画面にリダイレクト
        window.location.href = '/';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;