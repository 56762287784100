import React, { useState } from 'react';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';

export const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      console.log('Sending request to:', `${process.env.REACT_APP_API_URL}/forgot-password`);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        {
          email: email
        }
      );
      console.log('Response:', response.data);

      const responseMessage = response.data.message || 'パスワードリセット用のリンクを送信しました';
      setMessage(responseMessage);

      // 3秒後にリセットリンクへリダイレクト
      setTimeout(() => {
        if (response.data.reset_link) {

          const reset_url = process.env.REACT_APP_FRONTEND_URL + '/reset-password?' + response.data.reset_link;
          window.location.href = reset_url;
        }
      }, 3000);

    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          // サーバーからのレスポンスがある場合
          const statusCode = error.response.status;
          const errorData = error.response.data as { error?: string; detail?: string };
          
          if (statusCode === 404) {
            setMessage(errorData.error || 'ユーザーが見つかりません');
          } else {
            setMessage(errorData.error || errorData.detail || 'エラーが発生しました');
          }
        } else if (error.request) {
          // リクエストは送信されたがレスポンスがない場合
          setMessage('サーバーからの応答がありません');
        } else {
          // リクエストの設定中にエラーが発生した場合
          setMessage('リクエストの送信中にエラーが発生しました');
        }
        console.error('Error:', error.message);
      } else {
        setMessage('予期せぬエラーが発生しました');
        console.error('Unexpected error:', error);
      }
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
    <div className="w-[400px] p-10 bg-white rounded-lg shadow-md mx-auto">
      <h2 className="text-2xl font-bold mb-6">パスワードを忘れた方</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm mb-2">
            メールアドレスもしくはユーザー名
          </label>
          <input
            type="text"
            id="email"
            name="email"
            placeholder="name@domain.comもしくはuser name"
            className="w-full p-2 border border-gray-300 rounded"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
        </div>
        <button
          className="w-full p-2 bg-blue-500 text-white rounded"
          type="submit"
        >
          リセットリンクを作成
        </button>
      </form>
      {message && <p className="mt-4 text-green-600">{message}</p>}
      </div>
      </div>
  );
};

export default ForgotPassword;