import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connectWebSocket_progress, connectWebSocket_elapsedtime } from '../utils/websocket';
import Cookies from 'js-cookie';
import { Project } from '../types/types';
import { UserInfo } from '../types/types';
//import { useQueryUser } from '../hooks/useQueryUser'
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//import axios from 'axios';
import axiosInstance from '../utils/axiosSetup';
import axios from 'axios';

function TopComponent()
{
    //----------------------------->
    /* UserInfoを取得する */
    const location = useLocation();
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

    useEffect(() => {
/*        if (performance.getEntriesByType('navigation').length > 0) {
            const navigationEntry = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
            if (navigationEntry.type === 'back_forward') {
                window.location.reload();
            }
        }*/

        fetchUserInfo();
    }, []);

    const fetchUserInfo = useCallback(async () => {
        try {
            const response = await axiosInstance.get<UserInfo>(`${process.env.REACT_APP_API_URL}/user`, {
                withCredentials: true
            });
            console.log(response.data);
            setUserInfo(response.data);
            if (response.data.logo_filename) {
                fetchLogo();
            }
        } catch (error) {
            console.error('Error fetching user info:', error);
        }
    }, []);
    //-----------------------------<

    //----------------------------->
    /* ユーザ名編集 */
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState('');
    const history = useHistory();

    const handleEditClick = () => {
        setIsEditing(true);
        setEditName(userInfo?.name || '');
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditName(e.target.value);
    };

    const handleNameSubmit = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        console.log('keyboard = ', e.key)
        if (e.key === 'Enter') {
            await updateName();
        }
    };

    const updateName = async () => {
        try {
            await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update-name`, { name: editName }, {
                withCredentials: true
            });
            setIsEditing(false);
            fetchUserInfo();  // 更新後にユーザー情報を再取得
        } catch (error) {
            console.error('Error updating name:', error);
        }
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditName(userInfo?.name || '');
    };

    const handleLogout = async () => {
        console.log('Logout');
        try {
            // バックエンドのログアウト API を呼び出す
            await axiosInstance.post(`${process.env.REACT_APP_API_URL}/logout`, {}, {
                withCredentials: true
            });
            
            // ログイン画面にリダイレクト
            history.push('/');
        } catch (error) {
            console.error('Logout failed:', error);
            history.push('/');
        }
    };
    //-----------------------------<

    //----------------------------->
    /* ユーザー新規作成 */
    const handleCreateUser = () => {
        history.push('/signup');
    };
    //-----------------------------<

    //----------------------------->
    /* 動画再生モーダルウィンドウ */
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedVideoUrl, setSelectedVideoUrl] = useState("");

    const openModal = (space_name: string, movie_folder: string) => {
        if(space_name === "" || movie_folder === "")
        {
            return;
        }
        
        const videoUrl = `${process.env.REACT_APP_API_URL}/movie_output/${space_name}/${movie_folder}`;
        setSelectedVideoUrl(videoUrl);
        setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setSelectedVideoUrl("");
      setIsModalOpen(false);
    };
    //-----------------------------<
    
/*    const projects = [
        {
          id: 1,
          movie_folder: "0008",
          current_config: "movie_a.config",
          thumbnail: "sample1.png",
          project_name: "佐藤様 新築 江津湖畔",
          client_name: "佐藤",
          movie_title: "sato family haus",
          progress: 79,
          totalPhotos: 80,
          output: "https://u.pcloud.link/publink/show?code=XZ92T10Z3Y4r2Hlj3M5UrHyC7ggyEz98AqU7",
          lastEdited: "3 分前",
        },
        {
          id: 2,
          movie_folder: "0008",
          current_config: "movie_a.config",
          thumbnail: "sample1.png",
          project_name: "鈴木様 リフォーム 中央区",
          client_name: "佐藤",
          movie_title:"sato family haus",
          progress: 65,
          totalPhotos: 120,
          output: "http://localhost:3000//204951929875",
          lastEdited: "1 時間前",
        },
        {
            id: 3,
            movie_folder: "0008",
            current_config: "movie_a.config",
            thumbnail: "sample1.png",
            project_name: "鈴木様 リフォーム 中央区",
            client_name: "佐藤",
            movie_title:"sato family haus",
            progress: 100,
            totalPhotos: 120,
            output: "https://pcloud.com/download/204951929875",
            lastEdited: "1 時間前",
          },
          {
            id: 4,
            movie_folder: "0008",
            current_config: "movie_a.config",
            thumbnail: "sample1.png",
            project_name: "鈴木様 リフォーム 中央区",
            client_name: "佐藤",
            movie_title:"sato family haus",
            progress: 0,
            totalPhotos: 120,
            output: "",
            lastEdited: "1 時間前",
          }
      ];*/

/*      useEffect(() => {
        const getMovies = async () => {
          try {
            const spaceName = Cookies.get('space_name');
            const response = await fetch(`${process.env.REACT_APP_API_URL}/movies/${spaceName}`); // space_nameは適切な値に置き換えてください
            if (!response.ok) {
              throw new Error('Movies not found');
            }
            const data = await response.json();
            setMovies(data.movies);
          } catch (err: unknown) {
            if (err instanceof Error) {
              setError(err.message);
            } else {
              setError('An unknown error occurred');
            }
          }
        };
    
        getMovies();
      }, []);*/

    //----------------------------->
    /* プロジェクト取得&編集 */
    const [projects, setProjects] = useState<Project[]>([]);

    const fetchProjects = useCallback(async () => {
        try {
            const spaceName = Cookies.get('space_name');
            console.log("space_name = ", spaceName);
            // キャッシュを回避するためにタイムスタンプを追加
            const timestamp = new Date().getTime();
            const response = await fetch(`${process.env.REACT_APP_API_URL}/projects/${spaceName}?_=${timestamp}`);
            if (!response.ok) {
                throw new Error('Failed to fetch projects');
            }
            const data = await response.json();
            setProjects(data.projects as Project[]);
        } catch (error) {
            console.error('Error fetching projects:', error);
            setErrorMessage('プロジェクトの取得に失敗しました。システム管理者へ連絡してください。');
        }
    }, []);

    useEffect(() => {
        // ページロード時にプロジェクトを取得
        fetchProjects();

        // popstate イベントリスナーを追加
        const handlePopState = () => {
            //fetchProjects();
            window.location.reload();
        };

        window.addEventListener('popstate', handlePopState);

        // クリーンアップ関数
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [location]);

    const handleMovieClick = (movieFolder: string) => {
        const base_domain = window.location.origin;
        //window.location.href = `${base_domain}/movie/${movieFolder}`;
        //window.location.href = `${base_domain}/check/${movieFolder}`;
        window.location.href = `${base_domain}/movie_creator/${movieFolder}`;
    };
    //-----------------------------<

    //----------------------------->
    /* プロジェクト新規作成 */
    const [error, setError] = useState<string>('');
    const [newMovie, setNewMovie] = useState<string>('');
    
    const handleCreateMovie = async () => {
        try {
            const spaceName = Cookies.get('space_name');
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/create_movie/${spaceName}`, {});

            if (!response.data || !response.data.new_movie_folder) {
                setErrorMessage('新しいムービーフォルダの作成に失敗しました');
                return;
            }

            const newMovieFolder = response.data.new_movie_folder;
            console.log('新しいムービーフォルダ:', newMovieFolder);

            // 新しいムービーフォルダへリダイレクト
            const base_domain = window.location.origin;
            window.location.href = `${base_domain}/movie_creator/${newMovieFolder}`;
        } catch (error) {
            console.error('プロジェクトの新規作成に失敗しました:', error);
            if (axios.isAxiosError(error) && error.response) {
                const responseData = error.response.data as { detail?: string };
                if (error.response.status === 400 && responseData.detail) {
                    setErrorMessage(responseData.detail);
                } else {
                    setErrorMessage('プロジェクトの新規作成に失敗しました。システム管理者へ連絡してください。');
                }
            } else {
                setErrorMessage('プロジェクトの新規作成に失敗しました。システム管理者へ連絡してください。');
            }
        }
    };
    //-----------------------------<

    //----------------------------->
    /* プロジェクト削除 */
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [delete_project_movie_folder, setProjectToDelete] = useState<string | null>(null);

     const handleDeleteProject = (movie_folder: string) => {
        console.log(`Deleting project with ID: ${movie_folder}`);
        setProjectToDelete(movie_folder);
        setShowDeleteConfirm(true);
    };

    const confirmDelete = async () => {
        if (delete_project_movie_folder === null) return;

        try {
            await axiosInstance.delete(`${process.env.REACT_APP_API_URL}/projects/${userInfo?.space_name}/${delete_project_movie_folder}`, {
                withCredentials: true
            });
            console.log(`Project with ID: ${delete_project_movie_folder} deleted successfully`);
            setShowDeleteConfirm(false);
            setProjectToDelete(null);
            fetchProjects();
        } catch (error) {
            console.error('Failed to delete project:', error);
            setErrorMessage('プロジェクトの削除に失敗しました。システム管理者へ連絡してください。');
        }
    };

    const cancelDelete = () => {
        setShowDeleteConfirm(false);
        setProjectToDelete(null);
    };
    //-----------------------------<

    //----------------------------->
    /* 動画生成中の終了を監視する処理 */
    const [projectsInProgress, setProjectsInProgress] = useState<string[]>([]);

    useEffect(() => {
        const projectsGenerating = projects.filter(project => project.generate_task_id).map(project => project.generate_task_id!);
        setProjectsInProgress(projectsGenerating);
    }, [projects]);

    useEffect(() => {
        const websocketCleanups: (() => void)[] = [];

        projectsInProgress.forEach(taskId => {
            //const cleanupProgress = connectWebSocket_progress(taskId, (progress) => {
            //    // 進捗の更新処理（必要に応じて）
            //    console.log(`Task ${taskId} progress: ${progress}%`);
            //});

            const cleanupElapsedTime = connectWebSocket_elapsedtime(taskId, (elapsedTime, status) => {
                console.log(`Task ${taskId} elapsed time: ${elapsedTime}, status: ${status}`);
                if (status === "finished") {
                    // タスクが完了したら、プロジェクトリストを再取得
                    fetchProjects();
                    setProjectsInProgress(prev => prev.filter(id => id !== taskId));
                }
                else if(status === "no_id")
                {
                    const project = projects.find(p => p.generate_task_id === taskId);
                    if (project)
                    {
                        axiosInstance.post(`${process.env.REACT_APP_API_URL}/delete_movie/${userInfo?.space_name}/${project.movie_folder}`);
                    }
                    fetchProjects();
                    setProjectsInProgress(prev => prev.filter(id => id !== taskId));
                }
            
            }, handleWebSocketError);

            websocketCleanups.push(cleanupElapsedTime, cleanupElapsedTime);
        });

        return () => {
            websocketCleanups.forEach(cleanup => cleanup());
        };
    }, [projectsInProgress, fetchProjects, userInfo]);

    const handleWebSocketError = (error: string) => {
        console.error('WebSocketエラー:', error);
        // エラーメッセージを表示するなど、適切なエラー処理を行う
    };
    //-----------------------------<

    //----------------------------->
    /* ロゴをセットする処理 */
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const fetchLogo = useCallback(async () => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/logo`, {
                responseType: 'blob',
                withCredentials: true,
                headers: {
                    'Cache-Control': 'no-cache'
                }
            });
            const url = URL.createObjectURL(response.data);
            console.log("logo = ", url);
            setLogoUrl(url);
        } catch (error) {
            console.error('Error fetching logo:', error);
            setLogoUrl(null);
        }
    }, []);

    useEffect(() => {
        fetchUserInfo();

        // クリーンアップ関数
        return () => {
            if (logoUrl) {
                URL.revokeObjectURL(logoUrl);
            }

            if (thumbnails) {
                Object.values(thumbnails).forEach(url => {
                    URL.revokeObjectURL(url);
                });
            }
        };
    }, [fetchUserInfo]);

    const handleFileSelect = async (file: File) => {
        const formData = new FormData();
        formData.append('logo', file);
        try {
            await axiosInstance.post(`${process.env.REACT_APP_API_URL}/upload-logo`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });
            // ロゴアップロード後にユーザー情報とロゴを再取得
            await fetchUserInfo();
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 413) {
                setErrorMessage('画像サイズが大きすぎます。100MBより小さい画像を選択してください。');
            } else {
                setErrorMessage('ファイルのアップロードに失敗しました。システム管理者へ連絡してください。');
            }            
            console.error('Error uploading logo:', error);
        }
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith('image/')) {
            handleFileSelect(file);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            handleFileSelect(file);
        }
    };
    //-----------------------------<

    //----------------------------->
    /* ダウンロード */
    const handleDownload = async (spaceName: string, movieFolder: string) => {
        try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/movie_output/${spaceName}/${movieFolder}`, {
                responseType: 'blob',
            });
        // 現在の日時を取得
        const now = new Date();
        const dateString = now.getFullYear() +
                           ('0' + (now.getMonth() + 1)).slice(-2) +
                           ('0' + now.getDate()).slice(-2) + '_' +
                           ('0' + now.getHours()).slice(-2) +
                           ('0' + now.getMinutes()).slice(-2) +
                           ('0' + now.getSeconds()).slice(-2);

        // ファイル名を生成
        const fileName = `${movieFolder}_${dateString}.mp4`;

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error('動画のダウンロードに失敗しました:', error);
            setErrorMessage('動画のダウンロードに失敗しました。システム管理者へ連絡してください。');
        }
    };
    //-----------------------------<

    // 新しい状態と関数
    const [draggedOver_thumbnail, setDraggedOver_thumbnail] = useState<number | null>(null);

    const handleDragOver_thumbnail = (e: React.DragEvent<HTMLDivElement>, projectId: number) => {
        console.log("handleDragOver_thumbnail");
        e.preventDefault();
        setDraggedOver_thumbnail(projectId);
    };

    const handleDragLeave_thumbnail = () => {
        console.log("handleDragLeave_thumbnail");
        setDraggedOver_thumbnail(null);
    };

    const handleDrop_thumbnail = async (e: React.DragEvent<HTMLDivElement>, projectId: number, movieFolder: string) => {
        e.preventDefault();
        console.log("handleDrop_thumbnail started");
        setDraggedOver_thumbnail(null);
        const file = e.dataTransfer.files[0];
        console.log("Dropped file:", file);
    
        if (file && file.type.startsWith('image/')) {
            console.log("File is an image");
            try {
                const formData = new FormData();
                formData.append('thumbnail_file', file);
                console.log("FormData created", formData);
    
                console.log("Sending request to:", `${process.env.REACT_APP_API_URL}/update-thumbnail/${userInfo?.space_name || ''}/${movieFolder}`);
                const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update-thumbnail/${userInfo?.space_name || ''}/${movieFolder}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    withCredentials: true
                });
    
                console.log("Response received:", response);
    
                if (response.status === 200) {
                    console.log("Thumbnail update successful");
                    fetchProjects();
                }
            } catch (error) {
                console.error('Error updating thumbnail:', error);
                setErrorMessage('サムネイルの更新に失敗しました。システム管理者へ連絡してください。');
            }
        } else {
            console.log("File is not an image or no file was dropped");
        }
    };

    const [thumbnails, setThumbnails] = useState<{[key: string]: string}>({});
    const fetchThumbnails = useCallback(async () => {
        const spaceName = Cookies.get('space_name') || '';
        if (spaceName === '') {
            return;
        }
        const thumbnailPromises = projects.map(async (project) => {
            try {
                const thumbnailFilename = project.thumbnail || '';
                
                if (thumbnailFilename === '') {
                    return { [project.id]: '' };
                }

                const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/thumbnail_file/${spaceName}/${project.movie_folder}/${thumbnailFilename}`, {
                    responseType: 'blob'
                });
                const thumbnailUrl = URL.createObjectURL(response.data);
                return { [project.id]: thumbnailUrl };
            } catch (error) {
                console.error(`サムネイル取得エラー (プロジェクトID: ${project.id}):`, error);
                return { [project.id]: '' };
            }
        });
    
        const thumbnailResults = await Promise.all(thumbnailPromises);
        setThumbnails(Object.assign({}, ...thumbnailResults));
    }, [projects]);

    useEffect(() => {
        if (projects.length > 0) {
            fetchThumbnails();
        }
    }, [projects, fetchThumbnails]);

    const handleThumbnailUpdate = async (file: File, projectId: number, movieFolder: string) => {
        try {
            const formData = new FormData();
            formData.append('thumbnail_file', file);
            console.log("FormData created", formData);

            console.log("Sending request to:", `${process.env.REACT_APP_API_URL}/update-thumbnail/${userInfo?.space_name || ''}/${movieFolder}`);
            const response = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/update-thumbnail/${userInfo?.space_name || ''}/${movieFolder}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });

            console.log("Response received:", response);

            if (response.status === 200) {
                console.log("Thumbnail update successful");
                fetchProjects();
            }
        } catch (error) {
            console.error('Error updating thumbnail:', error);
            setErrorMessage('サムネイルの更新に失敗しました。システム管理者へ連絡してください。');
        }
    };

    const [activeProjectId, setActiveProjectId] = useState<number | null>(null);

    const handleThumbnailClick = (projectId: number) => {
        setActiveProjectId(projectId);
        // ファイル選択ダイアログを開く
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.png,.jpg,.jpeg';
        fileInput.onchange = (e) => {
            if (e.target instanceof HTMLInputElement) {
                handleFileInputChange_thumbnail(e as unknown as React.ChangeEvent<HTMLInputElement>, projectId);
            }
        };
        fileInput.click();
    };
    
    const handleFileInputChange_thumbnail = (e: React.ChangeEvent<HTMLInputElement>, projectId: number) => {
        const file = e.target.files?.[0];
        if (file) {
            const validTypes = ['image/png', 'image/jpeg'];
            const fileType = file.type;
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
    
            if (validTypes.includes(fileType) || 
                (fileType === 'image/jpeg' && fileExtension === 'jpg')) {
                const project = projects.find(p => p.id === projectId);
                if (project) {
                    handleThumbnailUpdate(file, projectId, project.movie_folder);
                }
            } else {
                alert('無効なファイル形式です。PNG、JPG、JPEG ファイルのみアップロード可能です。');
            }
        }
    };
    //----------------------------->
    /* エラーメッセージ */
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const clearErrorMessage = () => {
        setErrorMessage(null);
    };
    //-----------------------------<

    return (
        <div className="w-full max-w-[1024px] mx-auto p-4 min-w-[400px] overflow-x-auto">
            <header className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <div className="flex flex-col sm:flex-row items-center mb-4 sm:mb-0">
                    <div className="mr-0 sm:mr-4 text-lg font-semibold text-center sm:text-left mb-2 sm:mb-0">
                        <span className="font-noto-sans">
                            ログイン：
                            {isEditing ? (
                                <input
                                    type="text"
                                    value={editName}
                                    onChange={handleNameChange}
                                    onKeyDown={handleNameSubmit}
                                    className="border-b border-gray-300 focus:outline-none focus:border-blue-500"
                                />
                            ) : (
                                userInfo?.name || '名前を入れてください'
                            )}
                        </span>
                        {!isEditing && (
                            <button
                                onClick={handleEditClick}
                                className="ml-2 text-sm py-1 px-2 rounded transition duration-300 ease-in-out hover:bg-gray-200"
                            >
                                <img src="/edit_icon.svg" alt="Edit" className="w-6 h-6" />
                            </button>
                        )}
                    </div>
                    {isEditing ? (
                        <button
                            onClick={handleCancelEdit}
                            className="bg-gray-300 text-white py-1 px-4 rounded mb-2 sm:mb-0 transition duration-300 ease-in-out hover:bg-gray-500"
                        >
                            <span className="font-noto-sans">キャンセル</span>
                        </button>
                    ) : (
                        <button
                            onClick={handleLogout}
                            className="bg-gray-300 text-white py-1 px-4 rounded mb-2 sm:mb-0 transition duration-300 ease-in-out hover:bg-gray-500"
                        >
                            <span className="font-noto-sans">ログアウト</span>
                        </button>
                    )}
                </div>
        
                <div 
                    className={`w-40 h-40 flex items-center justify-center cursor-pointer ${
                        (!userInfo?.logo_filename || !logoUrl) ? 'border-2 border-dashed border-gray-300' : ''
                    }`}
                    onClick={handleClick}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                >
                    {userInfo?.logo_filename && logoUrl ? (
                        <img src={logoUrl} alt="COM-HAUS logo" className="max-w-full max-h-full" />
                    ) : (
                        <p className="text-center text-gray-500">ロゴをセットしてください</p>
                    )}
                    <input 
                        type="file" 
                        ref={fileInputRef} 
                        onChange={handleFileInputChange} 
                        accept="image/*" 
                        className="hidden" 
                    />
                </div>
            </header>
            <div className="flex justify-between items-center my-8">
                <div className={userInfo?.role === "admin" ? "w-[70%]" : "w-full"}>
                    <button 
                        className={`bg-gray-400 text-white py-2 px-8 rounded font-noto-sans text-lg w-full transition duration-300 ease-in-out hover:bg-gray-500`}
                        onClick={handleCreateMovie}
                    >
                        新しく案件を作成する
                    </button>
                </div>
                {userInfo?.role === "admin" && (
                    <div>
                        <button 
                            className="bg-yellow-200 text-dark py-2 px-8 rounded font-noto-sans text-lg transition duration-300 ease-in-out hover:bg-yellow-400" 
                            onClick={handleCreateUser}
                        >
                            ユーザーを新規作成
                        </button>
                    </div>
                )}
            </div>
            <section>
                <h2 className="text-2xl font-semibold font-noto-sans mb-4">
                現在の案件
                </h2>
                {projects.map((project) => (
                <div 
                    key={project.id} 
                    id={`project-${project.id}`} 
                    className="flex items-stretch bg-white shadow-md p-4 rounded-lg mb-4 cursor-pointer transition duration-300 ease-in-out hover:bg-gray-100 group" 
                    >
                    <div className="flex-1 text-center w-[100%] mt-1" onClick={(e) => {e.stopPropagation(); handleMovieClick(project.movie_folder)}}>
                        <div className="font-noto-sans text-xl font-semibold">
                            {project.project_name}
                        </div>
                        <div className="relative mt-4">
                            <div className="flex items-center justify-center">
                                <div
                                    className="bg-green-400 h-4 rounded-l"
                                    style={{ width: `${(project.progress / project.totalPhotos) * 80}%` }}>
                                </div>
                                <div 
                                    className="bg-gray-300 h-4 rounded-r" 
                                    style={{ width: `${80 - (project.progress / project.totalPhotos) * 80}%` }}>
                                </div>
                            </div>
                            <div className="mt-2 text-sm font-semibold font-noto-sans">
                                写真: {project.progress} / {project.totalPhotos}枚
                            </div>
                        </div>
                        <div className="flex flex-col sm:flex-row items-center justify-center mt-4">
                            {project.output ? (
                                <>
                                <div className="flex items-center mb-2 sm:mb-0 max-w-[80%]">
                                <button className="w-[200px] mr-2 bg-green-100 text-black text-lg font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out hover:bg-green-300" onClick={(e) => {e.stopPropagation(); openModal(userInfo?.space_name || '', project.movie_folder)}}>
                                    <img src="play-gray.svg" alt="Play" className="w-6 h-6 mr-2" />
                                    再生
                                </button>
                                <button className="w-[200px] mr-2 bg-blue-100 text-black text-lg font-bold py-2 px-4 rounded flex items-center justify-center transition duration-300 ease-in-out hover:bg-blue-300" 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleDownload(userInfo?.space_name || '', project.movie_folder);
                                        }}>
                                    <img src="download_icon.svg" alt="DL" className="w-6 h-6 mr-2" />
                                    ダウンロード
                                </button>
                                </div>
                                </>
                            ) : (
                                <span className={project.generate_task_id ? "text-blue-500" : "text-red-500"}>
                                    {project.generate_task_id ? "動画生成中..." : "未完成"}
                                </span>
                            )}
                        </div>
                        <div className="flex items-center justify-between mt-1">
                            <div className="flex-1 text-center">
                                <div className="text-gray-500 text-sm font-noto-sans mt-1">
                                    最後の編集 {project.lastEdited}
                                </div>
                            </div>
                            <div>
                                <button
                                onClick={(e) => {e.stopPropagation(); handleDeleteProject(project.movie_folder)}}
                                className=" bg-tranceparent border-none cursor-pointer p-2 rounded transition duration-300 ease-in-out transition duration-300 ease-in-out hover:bg-gray-300"
                                aria-label="Delete project"
                                >
                                <img src="/trash_icon.svg" alt="Delete" className="w-6 h-6" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>))}
            </section>
            {showDeleteConfirm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-6 rounded-lg">
                        <p>データはすべて削除され、復元することはできません。</p>
                        <p>このプロジェクトを削除しますか？</p>
                        <div className="mt-4 flex justify-end">
                            <button
                                onClick={cancelDelete}
                                className="mr-2 px-4 py-2 bg-gray-300 rounded"
                            >
                                キャンセル
                            </button>
                            <button
                                onClick={confirmDelete}
                                className="px-4 py-2 bg-red-500 text-white rounded"
                            >
                                削除
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {errorMessage && (
                <div className="fixed top-0 left-0 right-0 bg-red-500 text-white p-4 text-center">
                    {errorMessage}
                    <button onClick={clearErrorMessage} className="ml-4 underline">閉じる</button>
                </div>
            )}
            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50">
                    <div className="fixed inset-0 bg-gray-900 opacity-50" onClick={closeModal}></div>
                    <div className="bg-white p-4 rounded-lg z-10 w-4/5 max-h-[80vh] flex flex-col">
                        <div className="relative w-full" style={{ paddingBottom: '56.25%' }}>
                            <video 
                                src={selectedVideoUrl} 
                                controls 
                                autoPlay 
                                className="absolute top-0 left-0 w-full h-full object-contain"
                            ></video>
                        </div>
                        <button 
                            onClick={closeModal} 
                            className="mt-4 px-4 py-2 bg-gray-200 rounded self-end hover:bg-gray-300 transition-colors"
                        >
                            閉じる
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}


export default TopComponent;