import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';

export const ResetPassword: React.FC = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [token, setToken] = useState('');
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenFromQuery = searchParams.get('token');
        if (tokenFromQuery) {
            setToken(tokenFromQuery);
        } else {
            setMessage('トークンが見つかりません。');
        }
    }, [location]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        console.log(password);
        console.log(confirmPassword);
        if (password !== confirmPassword)
        {
            setMessage('パスワードが一致しません。');
            return;
        }
        
        console.log(token);

        if (!token) {
            setMessage('無効なトークンです。');
            return;
        }
        
        try
        {
            // バックエンドのパスワードリセット API を呼び出す
            await axios.post(`${process.env.REACT_APP_API_URL}/reset-password`,
            {
                token: token,
                new_password: password
            },
            {
                withCredentials: true
            });

            setMessage('パスワードが正常にリセットされました。');
            // 成功後、ログインページにリダイレクト
            setTimeout(() => history.push('/'), 6000);                
        } catch (error) {
            console.error('パスワードリセットに失敗しました:', error);
            if (axios.isAxiosError(error)) {
                if (error.response && error.response.data) {
                    setMessage((error.response.data as { detail?: string }).detail || 'パスワードリセットに失敗しました。');
                } else if (error.request) {
                    setMessage('サーバーからの応答がありません。');
                } else {
                    setMessage('リクエストの送信中にエラーが発生しました。');
                }
            } else {
                setMessage('予期せぬエラーが発生しました。');
            }
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 w-full">
            <div className="w-[400px] p-10 bg-white rounded-lg shadow-md mx-auto">
                <h2 className="text-2xl font-bold mb-6">新しいパスワードを設定</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="password" className="block text-sm mb-2">
                            新しいパスワード
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            className="w-full p-2 border border-gray-300 rounded"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="confirmPassword" className="block text-sm mb-2">
                            パスワードの確認
                        </label>
                        <input
                            type="password"
                            id="confirmPassword"
                            name="confirmPassword"
                            className="w-full p-2 border border-gray-300 rounded"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            required
                        />
                    </div>
                    <button
                    className="w-full p-2 bg-blue-500 text-white rounded"
                    type="submit"
                    >
                    パスワードをリセット
                    </button>
                </form>
                {message && <p className="mt-4 text-green-600">{message}</p>}
            </div>
        </div>
    );
};

export default ResetPassword;