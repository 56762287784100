import React, { useState, useEffect } from 'react';
import { connectWebSocket_elapsedtime } from '../utils/websocket';
import axiosInstance from '../utils/axiosSetup';

interface MovieGeneratorProps {
  spaceId: string | undefined;
  folderId: string;
  configFileName: string;
  isGenerating: boolean;
  task_id: string | null;
  onStartMovieGenerate: () => void;
  onMovieGenerated: () => void;
}

const MovieGenerator: React.FC<MovieGeneratorProps> = ({ spaceId, folderId, configFileName, isGenerating, task_id, onStartMovieGenerate, onMovieGenerated }) => {
  const [elapsedTime, setElapsedTime] = useState<number | null>(null);
  const [taskId, setTaskId] = useState<string | null>(task_id);
  const [status, setStatus] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    let closeWebSocket: (() => void) | null = null;
    if (taskId) {
      const handleElapsedTimeUpdate = (time: number, newStatus: string) => {
        setElapsedTime(time);
        setStatus(newStatus);
        if (newStatus === "finished") {
          setTaskId(null);
          onMovieGenerated();
        }
        else if(newStatus === "no_id")
        {
          setTaskId(null);
          axiosInstance.post(`${process.env.REACT_APP_API_URL}/delete_movie/${spaceId}/${folderId}`);
        }
      };
      const handleWebSocketError = (error: string) => {
        console.error('WebSocketエラー:', error);
        setErrorMessage(error);
        setStatus("failed");
        setTaskId(null);
      };
      closeWebSocket = connectWebSocket_elapsedtime(taskId, handleElapsedTimeUpdate, handleWebSocketError);
    }
    return () => {
      if (closeWebSocket) {
        closeWebSocket();
      }
    };
  }, [taskId]);

  const handleGenerateMovie = async () => {
    try {
      setErrorMessage(null); // エラーメッセージをリセット
      // 既存の動画ファイルを削除
      await axiosInstance.post(`${process.env.REACT_APP_API_URL}/delete_movie/${spaceId}/${folderId}`);

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/generate_movie/${spaceId}/${folderId}/${configFileName}`,
        {}
      );
      setTaskId(response.data.task_id);
      console.log("task_id:", response.data.task_id);
      
      onStartMovieGenerate();
    } catch (error) {
      console.error('動画生成エラー:', error);
      setErrorMessage('動画生成の開始に失敗しました。しばらく経ってから再度実行してください。');
      setStatus("failed");
    }
  };

  const formatElapsedTime = (seconds: number): string => {
    if (seconds < 60) {
      return `${seconds}秒`;
    } else {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}分${remainingSeconds}秒`;
    }
  };


  return (
    <div className="flex flex-col items-center">
      <button 
        onClick={handleGenerateMovie} 
        disabled={isGenerating || status === "making" || status === "ready"}
        className={`w-[400px] px-4 py-2 bg-gray-500 text-white rounded ${(isGenerating || status === "making" || status === "ready") ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-600'}`}
      >
        {status === "making" ? '生成中...' : 'ムービーを生成'}
      </button>
      <div className="h-20 mt-4 flex flex-col items-center justify-center">
        {status === "waiting" && (
          <p>別の動画生成の完了待ちです。長時間待つことが予測されます。</p>
        )}
        {status === "ready" && (
          <p>動画生成の順番待ちです。長時間待つことが予測されます。</p>
        )}
        {status === "making" && elapsedTime !== null && (
          <>
            <p>経過時間: {formatElapsedTime(elapsedTime)}</p>
            <p className="text-sm text-gray-500 mt-1">※ 動画生成には10～30分程度時間がかかります</p>
          </>
        )}
        {status === "finished" && (
          <p>動画生成が完了しました！</p>
        )}
        {status === "no_id" && (
          <p className="text-red-500">
            {errorMessage || '動画生成中にエラーが発生しました。しばらく経ってから再度お試しください。'}
          </p>
        )}
        {status === "failed" && (
          <p className="text-red-500">
            {errorMessage || '動画生成に失敗しました...'}
          </p>
        )}
      </div>
    </div>
  );
};

export default MovieGenerator;