import { useEffect } from 'react'
import axios from 'axios'   
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { CsrfToken } from './types/types'
import { useAppSelector } from './app/hooks'
import { selectCsrfState } from './slices/appSlice'
import { Auth } from './components/Auth'
import { Todo } from './components/Todo'
import { Movie } from './components/Movie' // 修正: 絶対パスから相対パスに変更
import NewMovie from "./components/NewMovie"
import MovieCreator from "./components/MovieCreator"
import TopComponent from './components/Top'
import CheckMovie from "./components/checkMovie"
import AuthSignUp from './components/AuthSignUp'
import AuthResetPassword from './components/AuthResetPassword'
import AuthForgotPassword from './components/AuthForgotPassword'

function App() {
  const csrf = useAppSelector(selectCsrfState)
  useEffect(() => {
    const getCsrfToken = async () => {
      const res = await axios.get<CsrfToken>(
        `${process.env.REACT_APP_API_URL}/csrftoken`
      )
      axios.defaults.headers.common['X-CSRF-Token'] = res.data.csrf_token
      //console.log(res.data.csrf_token)
    }
    getCsrfToken()
  }, [csrf])
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Auth />
        </Route>
        <Route exact path="/signup">
          <AuthSignUp />
        </Route>
        <Route exact path="/reset-password">
          <AuthResetPassword />
        </Route>
        <Route exact path="/forgot-password">
          <AuthForgotPassword />
        </Route>
        <Route exact path="/todo">
          <Todo />
        </Route>
        <Route exact path="/top">
          <TopComponent />
        </Route>
        <Route exact path="/movie">
          <Movie />
        </Route>
        <Route exact path="/check/:new_folder_name">
          <CheckMovie />
        </Route>
        <Route exact path="/movie/:new_folder_name">
          <NewMovie />
        </Route>
        <Route exact path="/movie_creator/:new_folder_name">
          <MovieCreator />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default App
